import actions from './actions'
import getters from './getters'
import mutations from './mutations'
import {DateTime} from 'luxon'

const state = {
  tasks: [],
  to: DateTime.local().toISODate(),
  from: DateTime.local().toISODate(),
  apiRoute: 'tasks'
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
