<template>
  <v-card color="grey lighten-4" flat tile>
    <v-toolbar class="primary primaryText--text" flat height="100">
      <logo header></logo>
      <span class="title font-weight-medium pl-2"> - Affiliate Manager</span>
      <v-spacer></v-spacer>
      <server-time v-if="isUIReady"></server-time>
      <v-badge
        v-if="isUIReady"
        :content="notificationLength"
        :value="notificationLength"
        color="error"
        class="ml-4 mr-4"
        dot
        overlap
        @click.native="route('notifications')"
      >
        <v-icon color="primaryText">mdi-bell-outline</v-icon>
      </v-badge>
      <v-menu
        offset-y
        max-width="340"
        v-if="isUIReady"
        class="d-flex justify-end align-start"
      >
        <template #activator="{on, attrs}">
          <v-btn
            class="subtitle-1 font-weight-light"
            v-bind="attrs"
            v-on="on"
            justify-end
            fab
            elevation="1"
            small
            >{{ getAvatar }}</v-btn
          >
        </template>
        <v-card tile>
          <v-list>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title
                  ><small
                    >Signed in as <strong>{{ getUsername }}</strong></small
                  ></v-list-item-title
                >
                <v-list-item-subtitle
                  ><small
                    >Account <strong>{{ getAccountName }}</strong></small
                  ></v-list-item-subtitle
                >
                <v-list-item-subtitle
                  ><small
                    >Role <strong>{{ getRoleName }}</strong></small
                  ></v-list-item-subtitle
                >
              </v-list-item-content>
            </v-list-item>
          </v-list>
          <v-divider></v-divider>
          <v-list dense class="pa-0">
            <v-list-item @click.native="route('settings')">
              <v-list-item-content>
                <v-list-item-title>Settings</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item @click.native="route('signout')">
              <v-list-item-content>
                <v-list-item-title>Sign out</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card>
      </v-menu>
      <template #extension v-if="isUIReady">
        <v-tabs
          class="primary"
          hide-slider
          optional
          active-class="secondary secondaryText--text"
          v-if="isUIReady"
        >
          <v-tab
            v-for="t in tabs"
            :key="t.title"
            :to="t.route"
            class="primaryText--text"
          >
            {{ t.title }}
          </v-tab>
        </v-tabs>
      </template>
    </v-toolbar>
  </v-card>
</template>

<script>
import {mapGetters} from 'vuex'
import permissionsMixin from '../../mixins/permissions'
import stationsMixin from '../../mixins/stations'
import ServerTime from './ServerTime'
import Logo from './Logo'

const NBC_NETWORK_SKYPATH = '2709081577'

export default {
  name: 'AppHeader',
  mixins: [permissionsMixin, stationsMixin],
  data: () => ({
    syncInterval: null,
    tabs: [
      {title: 'tasks', route: '/tasks'},
      {title: 'rules', route: '/rules'},
      {title: 'schedule', route: '/schedule'}
    ]
  }),
  computed: {
    ...mapGetters('app', ['isUIReady']),
    ...mapGetters('station', ['getStation']),
    ...mapGetters('notification', ['notificationLength']),
    ...mapGetters('auth', [
      'getUsername',
      'getAvatar',
      'getAccountName',
      'getRoleName',
      'getAccountRoles',
      'getAccountID'
    ])
  },
  mounted() {
    this.sync()
    this.addorRemoveScheduleOverrideTab()
    // Every 3 minutes
    this.syncInterval = setInterval(this.sync, 180000)
  },
  updated() {
    this.addorRemoveScheduleOverrideTab()
  },
  beforeDestroy() {
    if (this.syncInterval) {
      clearInterval(this.syncInterval)
      this.syncInterval = null
    }
  },
  methods: {
    sync() {
      if (this.isUIReady) {
        this.$asyncComputed.stations.update()
        this.$asyncComputed.allRoles.update()
      }
    },
    route(routeName) {
      this.$router
        .push({
          name: routeName
        })
        .catch(() => {})
    },
    addorRemoveScheduleOverrideTab() {
      if (this.getAccountID === NBC_NETWORK_SKYPATH) {
          this.tabs = [
            {title: 'schedule', route: '/schedule'},
            {title: 'schedule overrides', route: '/scheduleoverrides'}
        ]
      } else {
        this.tabs = [
            {title: 'tasks', route: '/tasks'},
            {title: 'rules', route: '/rules'},
            {title: 'schedule', route: '/schedule'}
        ]
      }    
    }
  },
  components: {
    ServerTime,
    Logo
  }
}
</script>
