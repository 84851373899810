import {
    SET_STATION,
    SET_STATIONS,
    SET_STATIONS_STATE_EMPTY,
    SET_TASKS_STATION,
    SET_RULES_STATION,
    SET_SCHEDULE_STATION,
    SET_SCHEDULE_OVERRIDES_STATION,
    SET_SETTINGS_STATION,
    SET_NOTIFICATIONS_STATION,
    CLEAR,
    SET_ALL_STATIONS
} from '../../types'

export default {
    [SET_STATION](state, data) {
        state.station = data
    },
    [SET_STATIONS](state, data) {
        state.stations = data
    },
    [SET_STATIONS_STATE_EMPTY](state, data) {
        state.stationsEmptyState = data
    },
    [SET_TASKS_STATION](state, data) {
        state.tasksStation = data
    },
    [SET_RULES_STATION](state, data) {
        state.rulesStation = data
    },
    [SET_SCHEDULE_STATION](state, data) {
        state.scheduleStation = data
    },
    [SET_SCHEDULE_OVERRIDES_STATION](state, data) {
        state.scheduleOverridesStation = data
    },
    [SET_SETTINGS_STATION](state, data) {
        state.settingsStation = data
    },
    [SET_NOTIFICATIONS_STATION](state, data) {
        state.notificationsStation = data
    },
    [CLEAR](state) {
        state.station = ''
        state.stations = []
        state.tasksStation = []
        state.rulesStation = []
        state.scheduleStation = []
        state.scheduleOverridesStation = []
        state.settingsStation = ''
        state.notificationsStation = ''
    },
    [SET_ALL_STATIONS](state, data) {
        let dataString = ''
        let dataArray = []

        switch (typeof data) {
            case 'object':
                dataString = data[0]
                dataArray = data
                break
            case 'string':
                dataString = data
                dataArray = [data]
                break
        }

        state.station = dataString
        state.tasksStation = dataArray
        state.rulesStation = dataArray
        state.scheduleStation = dataString
        state.scheduleOverridesStation = dataArray
        state.settingsStation = dataString
        state.notificationsStation = dataArray
    }
}