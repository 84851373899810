<template>
  <section>
    <v-row v-if="isUIReady" class="secondary pt-1">
      <v-col class="pb-0">
        <account-selector dense @change="setPermissions"></account-selector>
      </v-col>
      <v-col class="pb-0">
        <stations
          dense
          multiple
          :station="getTasksStation"
          @station-change="
            loading = true
            setTasksStation($event)
          "
        ></stations>
      </v-col>
    </v-row>
    <v-card flat class="mt-3">
      <v-card-title class="pr-0 pl-0 pb-0">
        <v-row flat dense>
          <v-col class="d-flex align-center">
            <h1 class="display-1 primary--text font-weight-bold">
              Tasks
              <span
                class="caption text-right primary--text font-weight-bold"
                v-if="!getCanEdit"
              >
                (Read-Only)</span
              >
            </h1>
          </v-col>
          <v-spacer></v-spacer>
          <v-col cols="12" lg="4" class="pl-4">
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Search"
              single-line
              clearable
              hide-details
            ></v-text-field>
          </v-col>
          <v-col cols="12" lg="1" dense>
            <v-menu
              ref="taskdatepicker"
              v-model="datePickerFromOpen"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              max-width="290px"
              min-width="290px"
            >
              <template v-slot:activator="{on}">
                <v-text-field
                  v-model="datePickerFromFormatted"
                  append-icon="mdi-calendar"
                  label="From"
                  class="pl-3"
                  readonly
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="dateFrom"
                no-title
                show-current="false"
                :value="dateFrom"
                :max="dateTo"
                @change="saveFrom(), (datePickerFromOpen = false)"
              >
                <v-spacer></v-spacer>
                <v-btn
                  color="primary"
                  class="primaryText--text"
                  @click="todayFrom"
                  >Today</v-btn
                >
              </v-date-picker>
            </v-menu>
          </v-col>
          <v-col cols="12" lg="1" dense>
            <v-menu
              ref="taskdatepicker"
              v-model="datePickerToOpen"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              max-width="290px"
              min-width="290px"
            >
              <template v-slot:activator="{on}">
                <v-text-field
                  v-model="datePickerToFormatted"
                  append-icon="mdi-calendar"
                  label="To"
                  class="pl-3"
                  readonly
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="dateTo"
                :min="dateFrom"
                no-title
                show-current="false"
                @change="saveTo(), (datePickerToOpen = false)"
              >
                <v-spacer></v-spacer>
                <v-btn
                  color="primary"
                  class="primaryText--text"
                  @click="todayTo"
                  >Today</v-btn
                >
              </v-date-picker>
            </v-menu>
          </v-col>
        </v-row>
      </v-card-title>
      <v-data-table
        v-model="selected"
        :headers="headers"
        @click:row="editItem"
        :show-select="getCanEdit"
        item-key="guid"
        :items="tasks"
        :search="search"
        no-results-text="No matching tasks found."
        no-data-text="No tasks found. Maybe a station needs to be selected"
        loading-text="Loading tasks..."
        :loading="loading"
      >
        <template #top>
          <v-row class="pb-1 mt-0" flat>
            <v-col class="d-flex align-center justify-end pt-0">
              <confirm v-if="getCanEdit" ref="confirm"></confirm>
              <v-btn
                class="my-0"
                color="success"
                medium
                elevation="0"
                dark
                v-if="selected.length && getCanEdit && hasWritableAccountRoles"
                @click.native="editSelected"
                >Edit Selected</v-btn
              >
              <v-dialog
                v-model="dialog"
                eager
                max-width="600px"
                @keydown.esc="close"
                @click:outside="close"
              >
                <v-card>
                  <v-toolbar dark color="primary" class="primaryText--text">
                    <v-toolbar-title>{{ formTitle }}</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-btn icon dark @click.native="close">
                      <v-icon>mdi-close</v-icon>
                    </v-btn>
                  </v-toolbar>
                  <rights-events-form ref="eventsForm" :view="tasksview"></rights-events-form>
                </v-card>
              </v-dialog>
            </v-col>
          </v-row>
          <v-divider></v-divider>
        </template>
        <template #[`item.station`]="{item}">
          {{ item.station.toUpperCase() | fillEmpty }}
        </template>
        <template #[`item.type`]="{item}">
          {{ item.type | fillEmpty }}
        </template>
        <template #[`item.start`]="{item}">
          {{ item.start | userTime }}
        </template>
        <template #[`item.end`]="{item}">
          {{ item.end | userTime }}
        </template>
      </v-data-table>
    </v-card>
  </section>
</template>

<script>
import {DateTime} from 'luxon'
import {mapGetters, mapActions} from 'vuex'
import RightsEventsForm from '../components/form/RightsEvents'
import Confirm from '../components/dialog/Confirm'
import Stations from '../components/form/Stations'
import AccountSelector from '../components/auth/AccountSelector'
import permissionsMixin from '../mixins/permissions'
import _ from 'lodash'
import {SnackBus} from '../main'

export default {
  name: 'Tasks',
  mixins: [permissionsMixin],
  data: () => ({
    dialog: false,
    loading: true,
    valid: false,
    search: '',
    dateFrom: DateTime.local().toISODate(),
    dateTo: DateTime.local().toISODate(),
    datePickerFromOpen: false,
    datePickerToOpen: false,
    syncInterval: null,
    headers: [
      {
        text: 'Station',
        align: 'start',
        value: 'station'
      },
      {text: 'Show', value: 'showfull'},
      {text: 'Type', value: 'type'},
      {text: 'Start', value: 'start'},
      {text: 'End', value: 'end'}
    ],
    selected: [],
    stations: [],
    editedIndex: -1,
    showDefaultsButton: false,
    default: null,
    defaultsApplied: false,
    editedEvent: {
      station: '',
      application: '',
      showtitle: '',
      series: false,
      season: '',
      episode: '',
      embargo: false,
      startover: false,
      lookback: false,
      type: '',
      takeuhd: false,
      authkill: false
    },
    emptyEvent: {
      station: '',
      application: '',
      showtitle: '',
      series: false,
      season: '',
      episode: '',
      embargo: false,
      startover: false,
      lookback: false,
      type: '',
      takeuhd: false,
      uhdavailable: false,
      authkill: false
    },
    tasksview : 'tasks'
  }),
  computed: {
    ...mapGetters('auth', [
      'getCanEdit',
      'getAccountID',
      'getToken',
      'getAccountRoles',
      'hasAccountRolesForEvent',
      'hasWritableAccountRoles'
    ]),
    ...mapGetters('app', ['isUIReady']),
    ...mapGetters('station', ['getTasksStation']),
    ...mapGetters('task', ['getDateFrom', 'getDateTo']),
    ...mapGetters('time', ['getTimezone']),
    formTitle() {
      if (this.editedEvent.season !== '' || this.editedEvent.episode !== '') {
        return `${this.editedEvent.showtitle} - S[${this.editedEvent.season}] E[${this.editedEvent.episode}]`
      }
      return this.editedEvent.showtitle
    },
    datePickerFromFormatted() {
      return this.formatDate(this.getDateFrom)
    },
    datePickerToFormatted() {
      return this.formatDate(this.getDateTo)
    }
  },
  asyncComputed: {
    tasks: {
      lazy: true,
      get() {
        return this.requestTasks({
          station: this.getTasksStation,
          account: this.getAccountID,
          token: this.getToken,
          from: this.getDateFrom,
          to: this.getDateTo
        })
          .then((tasks) => {
            const that = this
            this.loading = false
            // If no roles at all, there is nothing to do.
            if (!this.hasWritableAccountRoles) return []

            // If the user has roles but there are restrictions
            // based on the event, they will be filtered out here.
            return _.reduce(
              tasks,
              function (arr, t) {
                if (that.hasAccountRolesForEvent(t)) {
                  arr.push(t)
                }
                return arr
              },
              []
            )
          })
          .catch(() => {
            this.loading = false
            return []
          })
      },
      default: []
    }
  },
  mounted() {
    if (this.getDateFrom !== '') {
      this.dateFrom = this.getDateFrom
    }
    if (this.getDateTo !== '') {
      this.dateTo = this.getDateTo
    }

    this.sync()
    // Every 10 seconds
    this.syncInterval = setInterval(this.sync, 10000)
  },
  beforeDestroy() {
    if (this.syncInterval) {
      clearInterval(this.syncInterval)
      this.syncInterval = null
    }
  },
  watch: {
    search() {
      this.selected = []
      return
    }
  },
  methods: {
    ...mapActions('task', [
      'requestTasks',
      'setTasksDateFrom',
      'setTasksDateTo'
    ]),
    ...mapActions('event', ['updateEvent']),
    ...mapActions('station', ['setTasksStation']),
    sync() {
      this.$asyncComputed.tasks.update()
    },
    async editItem(item) {
      this.editedEvent = Object.assign(this.editedEvent, item)
      this.dialog = true
      let obj = await this.$refs.eventsForm.open(this.editedEvent)
      if (_.isEmpty(obj)) {
        this.close()
        return
      }

      this.close()
      this.save([obj], obj.station)
    },
    async editSelected() {
      // If the selected events is only one there is no need to confirm.
      if (this.selected.length == 1) {
        this.editItem(this.selected[0])
        return
      }

      this.dialog = true
      let obj = await this.$refs.eventsForm.open(this.emptyEvent, {
        multiple: true
      })

      if (_.isEmpty(obj)) {
        this.close()
        return
      }

      if (
        await this.$refs.confirm.open(
          'Save Tasks',
          _.map(this.selected, (o) => o.station + ': ' + o.showfull).sort(),
          'You will save the following tasks:',
          {agreeBtnText: 'save', agreeBtnColor: 'success'}
        )
      ) {
        for (let i = 0; i < this.selected.length; i++) {
          const event = this.selected[i]
          if (obj.uhdavailable) {
            event.takeuhd = obj.takeuhd
          }
          event.embargo = obj.embargo
          event.lookback = obj.lookback
          event.startover = obj.startover
          event.authkill = obj.authkill
        }

        _.forEach(_.groupBy(this.selected, 'station'), (events, station) => {
          this.save(events, station)
        })
        this.close()
      }
    },
    close() {
      this.selected = []
      this.dialog = false
      this.editedEvent = Object.assign({}, this.emptyEvent)
      this.default = Object.assign({}, this.emptyEvent)
      this.defaultsApplied = false
    },
    save(events, station) {
      this.loading = true
      this.updateEvent({
        station: station,
        account: this.getAccountID,
        token: this.getToken,
        data: events
      })
        .then(() => {
          SnackBus.$emit('open', {
            text: 'Successfully updated task',
            icon: 'check',
            color: 'green'
          })
          this.$asyncComputed.tasks.update()
        })
        .catch((err) => {
          SnackBus.$emit('error', `Error updating event: ${err}`)
          this.$asyncComputed.tasks.update()
        })
    },
    formatDate(date) {
      if (!date) return null
      const [year, month, day] = date.split('-')
      return `${month}/${day}/${year}`
    },
    saveFrom() {
      this.loading = true
      this.setTasksDateFrom(this.dateFrom)
      if (DateTime.fromISO(this.dateFrom) >= DateTime.fromISO(this.dateTo)) {
        this.dateTo = this.dateFrom
        this.setTasksDateTo(this.dateFrom)
      }
    },
    saveTo() {
      this.loading = true
      this.setTasksDateTo(this.dateTo)
      if (DateTime.fromISO(this.dateTo) <= DateTime.fromISO(this.dateFrom)) {
        this.dateFrom = this.dateTo
        this.setTasksDateFrom(this.dateTo)
      }
    },
    todayFrom() {
      this.loading = true
      this.dateFrom = DateTime.local().toISODate()
      this.saveFrom()
      this.datePickerFromOpen = false
    },
    todayTo() {
      this.loading = true
      this.dateTo = DateTime.local().toISODate()
      this.saveTo()
      this.datePickerToOpen = false
    }
  },
  components: {
    RightsEventsForm,
    Stations,
    AccountSelector,
    Confirm
  }
}
</script>
