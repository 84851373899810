import Vue from 'vue'
import Router from 'vue-router'
import Schedule from '@/views/Schedule'
import ScheduleOverrides from '@/views/ScheduleOverrides'
import Rules from '@/views/Rules'
import Tasks from '@/views/Tasks'
import SignIn from '@/views/SignIn'
import Settings from '@/views/Settings'
import Notifications from '@/views/Notifications'
import Store from '../store/index'

Vue.use(Router)

const routes = [{
        path: '/',
        name: 'home',
        redirect: {
            name: 'tasks'
        }
    },
    {
        path: '/signin',
        name: 'signin',
        component: SignIn,
        meta: {
            title: 'LRMAM - Sign In',
            metaTags: [{
                    name: 'description',
                    content: 'The sign in page.'
                },
                {
                    property: 'og:description',
                    content: 'The sign in page.'
                }
            ]
        }
    },
    {
        path: '*',
        component: SignIn,
        meta: {
            title: 'LRMAM - Sign In',
            metaTags: [{
                    name: 'description',
                    content: 'The sign in page.'
                },
                {
                    property: 'og:description',
                    content: 'The sign in page.'
                }
            ]
        }
    },
    {
        path: '/signout',
        name: 'signout'
    },
    {
        path: '/schedule',
        name: 'schedule',
        component: Schedule,
        meta: {
            requiresAuth: true,
            title: 'LRMAM - Schedule',
            metaTags: [{
                    name: 'description',
                    content: 'The schedule is a visual timeline of a stations events.'
                },
                {
                    property: 'og:description',
                    content: 'The schedule is a visual timeline of a stations events.'
                }
            ]
        }
    },
    {
        path: '/scheduleoverrides',
        name: 'scheduleoverrides',
        component: ScheduleOverrides,
        meta: {
            requiresAuth: true,
            title: 'LRMAM - Schedule Overrides',
            metaTags: [{
                    name: 'description',
                    content: 'The schedule overrides'
                },
                {
                    property: 'og:description',
                    content: 'The schedule overrides'
                }
            ]
        }
    },
    {
        path: '/rules',
        name: 'rules',
        component: Rules,
        meta: {
            requiresAuth: true,
            title: 'LRMAM - Rules',
            metaTags: [{
                    name: 'description',
                    content: 'Rules that are set for a station events.'
                },
                {
                    property: 'og:description',
                    content: 'Rules that are set for a station events.'
                }
            ]
        }
    },
    {
        path: '/tasks',
        name: 'tasks',
        component: Tasks,
        meta: {
            requiresAuth: true,
            title: 'LRMAM - Tasks',
            metaTags: [{
                    name: 'description',
                    content: 'Task for affiliate operators to act on.'
                },
                {
                    property: 'og:description',
                    content: 'Task for affiliate operators to act on.'
                }
            ]
        }
    },
    {
        path: '/settings',
        name: 'settings',
        component: Settings,
        meta: {
            requiresAuth: true,
            title: 'LRMAM - Settings',
            metaTags: [{
                    name: 'description',
                    content: 'This is where the user can set configuration for a specific station.'
                },
                {
                    property: 'og:description',
                    content: 'This is where the user can set configuration for a specific station.'
                }
            ]
        }
    },
    {
        path: '/notifications',
        name: 'notifications',
        component: Notifications,
        meta: {
            requiresAuth: true,
            title: 'LRMAM - Notifications',
            metaTags: [{
                    name: 'description',
                    content: 'Notifications for the user.'
                },
                {
                    property: 'og:description',
                    content: 'Notifications for the user.'
                }
            ]
        }
    }
]

const router = new Router({
    mode: 'history',
    routes
})

router.beforeEach((to, from, next) => {
    // This goes through the matched routes from last to first, finding the closest route with a title.
    // eg. if we have /some/deep/nested/route and /some, /deep, and /nested have titles, nested's will be chosen.
    const nearestWithTitle = to.matched
        .slice()
        .reverse()
        .find((r) => r.meta && r.meta.title)

    // Find the nearest route element with meta tags.
    const nearestWithMeta = to.matched
        .slice()
        .reverse()
        .find((r) => r.meta && r.meta.metaTags)

    // If a route with a title was found, set the document (page) title to that value.
    if (nearestWithTitle) document.title = nearestWithTitle.meta.title

    // Remove any stale meta tags from the document using the key attribute we set below.
    Array.from(
        document.querySelectorAll('[data-vue-router-controlled]')
    ).map((el) => el.parentNode.removeChild(el))

    if (nearestWithMeta) {
        // Turn the meta tag definitions into actual elements in the head.
        nearestWithMeta.meta.metaTags
            .map((tagDef) => {
                const tag = document.createElement('meta')

                Object.keys(tagDef).forEach((key) => {
                    tag.setAttribute(key, tagDef[key])
                })

                // We use this to track which meta tags we create, so we don't interfere with other ones.
                tag.setAttribute('data-vue-router-controlled', '')

                return tag
            }) // Add the meta tags to the document head.
            .forEach((tag) => document.head.appendChild(tag))
    }

    // If sign out is requested. Set the current state of the user to authenticated false.
    if (to.path === '/signout') {
        Store.dispatch('auth/signOut')
            // Store.dispatch('app/setUIReady', false)
        next({
            path: '/signin'
        })
    }

    // If the meta of the route requires auth validate user auth.
    if (to.matched.some((record) => record.meta.requiresAuth)) {
        // this route requires auth, check if logged in
        // if not, redirect to signout page.
        if (!Store.getters['auth/isAuthenticated'] ||
            Store.getters['auth/getAccountName'] === ''
        ) {
            next({
                path: '/signin'
            })
        } else {
            const role = Store.getters['auth/getRole']
            if (to.matched.some((record) => record.meta.role)) {
                if (role >= to.meta.role) {
                    next()
                } else {
                    next(from)
                }
            } else {
                next()
            }
        }
    } else {
        next() // make sure to always call next()!
    }
})

export default router