<template>
  <section>
    <v-row v-if="isUIReady" class="secondary pt-1">
      <v-col class="pb-0">
        <account-selector dense @change="setPermissions"></account-selector>
      </v-col>
      <v-col class="pb-0">
        <stations
          dense
          :station="getSettingsStation"
          @station-change="
            loading = true
            setSettingsStation($event)
          "
        ></stations>
      </v-col>
    </v-row>
    <v-row dense class="mt-3">
      <v-col>
        <v-card flat>
          <v-card-title class="pr-0 pb-0 pl-0">
            <h1 class="display-1 primary--text font-weight-bold">Settings</h1>
          </v-card-title>
        </v-card>
      </v-col>
    </v-row>
    <v-card flat class="mt-4 pb-5">
      <v-card-title class="pa-0">
        <v-row flat dense>
          <v-col class="d-flex align-center">
            <h2 class="title">Email Notifications</h2>
          </v-col>
          <v-spacer></v-spacer>
          <v-col cols="12" lg="6" class="pl-4">
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Search"
              single-line
              clearable
              hide-details
            ></v-text-field>
          </v-col>
        </v-row>
      </v-card-title>
      <v-row>
        <v-col>
          <v-data-table
            v-model="selected"
            :show-select="getCanEdit"
            item-key="email"
            :headers="headers"
            :items="emails"
            no-results-text="No matching emails found."
            no-data-text="No emails found. Maybe a station needs to be selected"
            loading-text="Loading emails..."
            :search="search"
            @click:row="edit"
            :loading="loading"
          >
            <template #top>
              <v-row class="pb-1 mt-3" dense flat>
                <v-col class="d-flex justify-end align-end">
                  <confirm ref="confirm"></confirm>
                  <v-btn
                    color="error"
                    medium
                    elevation="0"
                    class="mr-2"
                    dark
                    v-if="selected.length"
                    @click.native="deleteSelected"
                    >Delete</v-btn
                  >
                  <v-dialog
                    v-if="getCanEdit"
                    v-model="dialog"
                    @click:outside="close"
                    @keydown.esc="close"
                    max-width="600px"
                  >
                    <template #activator="{on}">
                      <v-btn color="accent" medium outlined dark v-on="on"
                        >New Email</v-btn
                      >
                    </template>
                    <v-card>
                      <v-toolbar
                        fixed
                        color="primary"
                        class="primaryText--text"
                      >
                        <v-toolbar-title>Email</v-toolbar-title>
                        <v-spacer></v-spacer>
                        <v-btn icon dark @click.native="close">
                          <v-icon>mdi-close</v-icon>
                        </v-btn>
                      </v-toolbar>
                      <v-form
                        :disabled="!getCanEdit"
                        ref="form"
                        v-model="valid"
                      >
                        <v-card-text>
                          <v-text-field
                            v-model="editedEmail.email"
                            :value="editedEmail.email"
                            :rules="emailRules"
                            placeholder="user@email.com"
                            persistent-hint
                            hint="user@email.com"
                          >
                          </v-text-field>
                        </v-card-text>
                      </v-form>
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                          color="primary"
                          class="primaryText--text"
                          text
                          v-if="!getCanEdit"
                          @click.native="close"
                          >Close</v-btn
                        >
                        <v-btn
                          color="grey"
                          text
                          v-if="getCanEdit"
                          @click.native="close"
                          >Cancel</v-btn
                        >
                        <v-btn
                          depressed
                          color="accent"
                          v-if="getCanEdit"
                          :disabled="!valid || !getCanEdit"
                          @click.native="save"
                          >Save</v-btn
                        >
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                </v-col>
              </v-row>
              <v-divider></v-divider>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
      <v-row> </v-row>
    </v-card>
  </section>
</template>

<script>
import {mapGetters, mapActions} from 'vuex'
import _ from 'lodash'
import Confirm from '../components/dialog/Confirm'
import Stations from '../components/form/Stations'
import AccountSelector from '../components/auth/AccountSelector'
import permissionsMixin from '../mixins/permissions'
import {SnackBus} from '../main'

export default {
  name: 'Settings',
  mixins: [permissionsMixin],
  data: () => ({
    valid: false,
    search: '',
    loading: true,
    dialog: false,
    syncInterval: null,
    editedIndex: -1,
    editedEmail: {
      email: null,
      original: null
    },
    emptyEmail: {
      email: null,
      original: null
    },
    selected: [],
    emailRules: [(v) => /.+@.+\..+/.test(v) || 'E-mail must be valid'],
    headers: [{text: 'Email', value: 'email'}],
    email: null
  }),
  asyncComputed: {
    emails: {
      lazy: true,
      get() {
        return this.requestEmails({
          station: this.getSettingsStation
        })
          .then((emails) => {
            this.loading = false
            if (emails.length === 0) return []
            return emails
          })
          .catch((err) => {
            this.loading = false
            SnackBus.$emit('error', `Error getting emails: ${err}`)
            return []
          })
      },
      default: []
    }
  },
  computed: {
    ...mapGetters('auth', ['getCanEdit', 'getAccountID', 'getToken']),
    ...mapGetters('app', ['isUIReady']),
    ...mapGetters('station', ['getStation', 'getSettingsStation']),
    ...mapGetters('email', ['getEmails']),

    filteredItems() {
      return _.orderBy(
        this.emails.filter((item) => {
          if (!this.search) return this.emails
          return item.email.toLowerCase().includes(this.search.toLowerCase())
        }),
        'email'
      )
    }
  },
  watch: {
    getSettingsStation() {
      this.$asyncComputed.emails.update()
    },
    search() {
      this.selected = []
      return
    }
  },
  mounted() {
    this.sync()
    // Every 30 seconds
    this.syncInterval = setInterval(this.sync, 30000)
  },
  beforeDestroy() {
    if (this.syncInterval) {
      clearInterval(this.syncInterval)
      this.syncInterval = null
    }
  },
  methods: {
    ...mapActions('station', ['setSettingsStation']),
    ...mapActions('email', ['requestEmails', 'updateEmails']),
    edit(item) {
      // read only
      if (!this.getCanEdit) {
        return
      }

      this.editedIndex = _.findIndex(this.emails, item)
      this.editedEmail = Object.assign({}, item)
      this.editedEmail.original = item.email

      setTimeout(() => (this.dialog = true), 10)
      setTimeout(() => this.$refs.form.resetValidation(), 10)
    },
    sync() {
      this.$asyncComputed.emails.update()
    },
    save() {
      if (this.$refs.form.validate() && this.getCanEdit) {
        this.loading = true
        let emails = []

        if (this.getEmails) {
          emails = [...this.getEmails]
        }

        var index = _.findIndex(emails, {email: this.editedEmail.original})

        if (index > -1) {
          // Replace item at index using native splice
          emails.splice(index, 1, {email: this.editedEmail.email})
        } else {
          emails.push({email: this.editedEmail.email})
        }

        this.updateEmails({
          station: this.getSettingsStation,
          data: emails
        })
          .then(() => {
            SnackBus.$emit('open', {
              text: 'Successfully modifying emails',
              icon: 'check',
              color: 'green'
            })
            this.$asyncComputed.emails.update()
          })
          .catch((err) => {
            SnackBus.$emit('error', `Error modifying emails: ${err}`)
            this.$asyncComputed.emails.update()
          })
      }
      this.close()
    },
    close() {
      this.editedIndex = -1
      this.selected = []
      this.editedEmail = Object.assign({}, this.emptyEmail)
      setTimeout(() => (this.dialog = false), 10)
    },
    async deleteSelected() {
      if (
        await this.$refs.confirm.open(
          'Delete Email',
          _.map(this.selected, 'email'),
          'You will delete the following emails:'
        )
      ) {
        let emails = [...this.getEmails]
        this.updateEmails({
          station: this.getSettingsStation,
          data: _.pullAllBy(emails, this.selected, 'email')
        })
          .then(() => {
            SnackBus.$emit('open', {
              text: 'Successfully deleted emails',
              icon: 'check',
              color: 'green'
            })
            this.$asyncComputed.emails.update()
          })
          .catch((err) => {
            SnackBus.$emit('error', `Error deleting emails: ${err}`)
            this.$asyncComputed.emails.update()
          })

        this.loading = true
        this.close()
      }
    }
  },
  components: {
    Stations,
    Confirm,
    AccountSelector
  }
}
</script>
