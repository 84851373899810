<template>
  <section>
    <v-row v-if="isUIReady" class="secondary pt-1">
      <v-col class="pb-0">
        <account-selector dense @change="setPermissions"></account-selector>
      </v-col>
      <v-col class="pb-0">
        <stations
          dense
          multiple
          :station="getRulesStation"
          @station-change="
            loading = true
            setRulesStation($event)
          "
        ></stations>
      </v-col>
    </v-row>
    <v-card flat class="mt-3">
      <v-card-title class="pr-0 pl-0 pb-0">
        <v-row flat dense>
          <v-col class="d-flex align-end">
            <h1 class="display-1 primary--text font-weight-bold">
              Rules
              <span
                class="caption text-right primary--text font-weight-bold"
                v-if="!getCanEdit"
              >
                (Read-Only)</span
              >
            </h1>
          </v-col>

          <v-spacer></v-spacer>
          <v-col cols="12" lg="6" class="pl-4">
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Search"
              single-line
              clearable
              hide-details
            ></v-text-field>
          </v-col>
        </v-row>
      </v-card-title>
      <v-data-table
        v-model="selected"
        :show-select="getCanEdit"
        item-key="id"
        :headers="headersAvailable"
        :items="rules"
        no-results-text="No matching rules found."
        no-data-text="No rules found. Maybe a station needs to be selected"
        loading-text="Loading rules..."
        :search="search"
        @click:row="editRule"
        :loading="loading"
      >
        <template #top>
          <v-row class="pb-1 mt-1" flat>
            <v-col class="d-flex justify-end align-end">
              <confirm v-if="getCanEdit" ref="confirm"></confirm>
              <v-btn
                color="error"
                medium
                elevation="0"
                class="mr-2"
                dark
                v-if="selected.length && getCanEdit"
                @click.native="deleteSelected"
                >Delete</v-btn
              >
              <v-dialog
                v-model="dialog"
                @click:outside="close"
                max-width="600px"
              >
                <template #activator="{on}" v-if="getCanEdit">
                  <v-btn color="accent" medium outlined dark v-on="on"
                    >New Rule</v-btn
                  >
                </template>
                <v-card>
                  <v-toolbar fixed color="primary" class="primaryText--text">
                    <v-toolbar-title>{{ formTitle }}</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-btn icon dark @click.native="close">
                      <v-icon>mdi-close</v-icon>
                    </v-btn>
                  </v-toolbar>
                  <v-form :disabled="!getCanEdit" ref="form" v-model="valid">
                    <v-card-text>
                      <rules-dialog
                        :obj="editedRule"
                        @rulesChange="changeRule"
                      ></rules-dialog>
                    </v-card-text>
                  </v-form>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                      class="body-1 text--secondary"
                      text
                      v-if="!getCanEdit"
                      @click.native="close"
                      >Close</v-btn
                    >
                    <v-btn
                      class="body-1 text--secondary"
                      text
                      v-if="getCanEdit"
                      @click.native="close"
                      >Cancel</v-btn
                    >
                    <v-btn
                      depressed
                      color="accent"
                      v-if="getCanEdit"
                      :disabled="!valid || !getCanEdit"
                      @click.native="save"
                      >Save</v-btn
                    >
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-col>
          </v-row>
          <v-divider></v-divider>
        </template>
        <template #[`item.series`]="{item}">
          {{ item.series | isEnabled }}
        </template>
        <template #[`item.season`]="{item}">
          {{ item.season | fillEmpty }}
        </template>
        <template #[`item.episode`]="{item}">
          {{ item.episode | fillEmpty }}
        </template>
        <template #[`item.authkill`]="{item}">
          {{ item.authkill | isEnabled }}
        </template>
        <template #[`item.takeuhd`]="{item}">
          {{ item.takeuhd | isEnabled }}
        </template>
        <template #[`item.embargo`]="{item}">
          {{ item.embargo | isEnabled }}
        </template>
        <template #[`item.startover`]="{item}">
          {{ item.startover | isEnabled }}
        </template>
        <template #[`item.lookback`]="{item}">
          {{ item.lookback | isEnabled }}
        </template>
      </v-data-table>
    </v-card>
  </section>
</template>

<script>
import {mapGetters, mapActions} from 'vuex'
import RulesDialog from '../components/form/Rules'
import Confirm from '../components/dialog/Confirm'
import AccountSelector from '../components/auth/AccountSelector'
import Stations from '../components/form/Stations'
import permissionsMixin from '../mixins/permissions'
import _ from 'lodash'
import {SnackBus} from '../main'

export default {
  name: 'Rules',
  mixins: [permissionsMixin],
  data: () => ({
    search: '',
    valid: true,
    loading: false,
    dialog: false,
    selected: [],
    syncInterval: null,
    editedIndex: -1,
    editedRule: {
      stations: [],
      id: '',
      show: '',
      series: false,
      season: '',
      episode: '',
      embargo: false,
      startover: false,
      lookback: false,
      uhdavailable: false,
      takeuhd: false,
      authkill: false
    },
    emptyRule: {
      stations: [],
      id: '',
      show: '',
      series: false,
      season: '',
      episode: '',
      embargo: false,
      startover: false,
      lookback: false,
      uhdavailable: false,
      takeuhd: false,
      authkill: false
    },
    headers: [
      {text: 'Station', value: 'stations'},
      {text: 'Show', value: 'show'},
      {text: 'Series', value: 'series'},
      {text: 'Season', value: 'season'},
      {text: 'Episode', value: 'episode'},
      {text: 'AuthKill', value: 'authkill', apiKey: 'authKill'},
      {text: 'UHD', value: 'takeuhd', apiKey: 'uhd'},
      {text: 'Embargo', value: 'embargo', apiKey: 'embargo'},
      {text: 'Startover', value: 'startover', apiKey: 'startOver'},
      {text: 'Lookback', value: 'lookback', apiKey: 'lookBack'}
    ]
  }),
  computed: {
    ...mapGetters('auth', [
      'getCanEdit',
      'getAccountID',
      'getToken',
      'getRole',
      'getAccountRoles'
    ]),
    ...mapGetters('app', ['isUIReady']),
    ...mapGetters('station', [
      'getStationNames',
      'getRulesStation',
      'getStation'
    ]),
    ...mapGetters('rule', ['getRules']),
    formTitle() {
      return this.editedIndex === -1 ? 'New Item' : 'Edit Item'
    },
    headersAvailable() {
      // This will update the headers based on the feature roles enabled for this account.
      var removeKeys = _.keys(_.pickBy(this.getAccountRoles, (v) => !v))
      return _.reduce(
        this.headers,
        function (result, value) {
          if (!_.includes(removeKeys, value.apiKey)) {
            result.push(value)
          }
          return result
        },
        []
      )
    }
  },
  asyncComputed: {
    rules: {
      lazy: true,
      get() {
        return this.requestRules({
          station: this.getRulesStation,
          account: this.getAccountID,
          token: this.getToken
        })
          .then((rules) => {
            if (rules.length === 0) return []
            return rules
          })
          .catch(() => {
            SnackBus.$emit('error', 'Error getting rules')
            return []
          })
      },
      default: []
    }
  },
  methods: {
    ...mapActions('rule', [
      'requestRules',
      'updateRule',
      'createRule',
      'deleteRules'
    ]),
    ...mapActions('station', ['setRulesStation']),
    sync() {
      this.$asyncComputed.rules.update()
    },
    save() {
      if (this.$refs.form.validate() && this.getCanEdit) {
        let rule = _.cloneDeep(this.editedRule)

        if (!rule.season && !rule.episode && !rule.series) {
          rule.series = true
        }

        if (rule.series) {
          rule.season = ''
          rule.episode = ''
        }

        // if multiple stations are selected the user
        // is wanting this rule to apply to multiple stations
        // so the id is no long used.
        if (rule.stations.length > 1) {
          rule.id = ''
        }

        this.loading = true

        // Update
        if (this.editedIndex > -1) {
          this.updateRule({
            station: rule.stations,
            account: this.getAccountID,
            token: this.getToken,
            data: [rule]
          })
            .then(() => {
              SnackBus.$emit('open', {
                text: 'Successfully updated rule',
                icon: 'check',
                color: 'green'
              })
              this.$asyncComputed.rules.update()
              this.loading = false
            })
            .catch(() => {
              SnackBus.$emit('error', 'Error updating rule')
              this.$asyncComputed.rules.update()
              this.loading = false
            })
        } else {
          // Create
          this.createRule({
            station: rule.stations,
            account: this.getAccountID,
            token: this.getToken,
            data: [rule]
          })
            .then(() => {
              SnackBus.$emit('open', {
                text: 'Successfully created rule',
                icon: 'check',
                color: 'green'
              })
              this.$asyncComputed.rules.update()
              this.loading = false
            })
            .catch(() => {
              SnackBus.$emit('error', 'Error creating rule')
              this.$asyncComputed.rules.update()
              this.loading = false
            })
        }

        this.close()
        return
      }
    },
    close() {
      this.dialog = false
    },
    editRule(item) {
      this.editedIndex = _.findIndex(this.getRules, item)
      this.editedRule = Object.assign({}, item)
      setTimeout(() => (this.dialog = true), 10)
      setTimeout(() => this.$refs.form.resetValidation(), 10)
    },
    async deleteSelected() {
      if (
        await this.$refs.confirm.open(
          'Delete rules',
          _.map(this.selected, 'show'),
          'You will delete the following rules:'
        )
      ) {
        this.loading = true
        this.deleteRules({
          account: this.getAccountID,
          token: this.getToken,
          data: this.selected
        })
          .then(() => {
            this.selected = []
            SnackBus.$emit('open', {
              text: 'Successfully deleted rule',
              icon: 'check',
              color: 'green'
            })
            this.$asyncComputed.rules.update()
            this.loading = false
          })
          .catch(() => {
            this.selected = []
            SnackBus.$emit('error', 'Error deleting rule')
            this.$asyncComputed.rules.update()
            this.loading = false
          })
        this.close()
      }
    },
    changeRule(event) {
      if (!this.dialog) return
      this.editedRule = event
      setTimeout(() => this.$refs.form.validate(), 20)
    }
  },
  watch: {
    dialog(val) {
      if (val) {
        setTimeout(() => this.$refs.form.resetValidation(), 10)
        return val
      }
      this.editedIndex = -1
      this.selected = []
      this.editedRule = Object.assign(this.editedRule, this.emptyRule)
      return val
    },
    search() {
      this.selected = []
      return
    }
  },
  mounted() {
    this.sync()
    // Every 10 seconds
    this.syncInterval = setInterval(this.sync, 10000)
  },
  beforeDestroy() {
    if (this.syncInterval) {
      clearInterval(this.syncInterval)
      this.syncInterval = null
    }
  },
  components: {
    RulesDialog,
    Confirm,
    Stations,
    AccountSelector
  }
}
</script>
