<template>
  <v-row justify="center" class="pt-10">
    <v-card
      width="600"
      v-if="!isAuthenticated"
      class="align-start elevation-12"
    >
      <v-toolbar dark color="primary" class="primaryText--text">
        <v-toolbar-title>Sign In</v-toolbar-title>
      </v-toolbar>
      <v-card-text>
        <v-alert outlined v-model="error" dismissible type="error">{{
          authError
        }}</v-alert>
        <v-form ref="form" v-model="valid" lazy-validation>
          <v-text-field
            prepend-icon="person"
            v-model="username"
            :rules="usernameRules"
            label="Username"
            autocomplete="username"
            required
          ></v-text-field>
          <v-text-field
            prepend-icon="lock"
            v-model="password"
            :rules="passwordRules"
            label="Password"
            autocomplete="current-password"
            :type="show ? 'text' : 'password'"
            :append-icon="show ? 'visibility_off' : 'visibility'"
            @click:append="show = !show"
            required
          ></v-text-field>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <div class="flex-grow-1"></div>
        <v-btn
          color="primary"
          class="primaryText--text"
          v-if="!isAuthenticated"
          :disabled="!valid"
          @click.native="submit"
          >Sign In
        </v-btn>
      </v-card-actions>
    </v-card>
    <v-card v-if="isAuthenticated" width="600" class="align-start elevation-12">
      <account-select buttons form></account-select>
    </v-card>
  </v-row>
</template>

<script>
import {mapActions, mapGetters} from 'vuex'
import AccountSelect from './AccountSelect'

export default {
  name: 'SignIn',
  data: () => ({
    valid: true,
    show: false,
    username: '',
    password: '',
    authError: {},
    error: false,
    usernameRules: [(v) => !!v || 'Email is required'],
    passwordRules: [(v) => !!v || 'Password is required']
  }),
  created() {
    this.username = this.getUsername
  },
  watch: {
    getUsername() {
      this.username = this.getUsername
    }
  },
  computed: {
    ...mapGetters('auth', ['getUsername', 'isAuthenticated', 'getToken'])
  },
  methods: {
    ...mapActions('auth', ['requestAuth', 'requestAccounts']),
    submit() {
      if (this.$refs.form.validate()) {
        this.requestAuth({
          data: {
            username: this.username,
            password: this.password
          }
        })
          .then(() => {
            this.password = ''
          })
          .catch((reason) => {
            this.password = ''
            if (typeof reason === 'string') {
              this.authError = reason
              this.error = true
            } else {
              this.authError = 'Could not reach the sign in server.'
              this.error = true
            }
          })
      }
    },
    route(link) {
      this.$router.push({
        name: link
      })
    }
  },
  components: {
    AccountSelect
  }
}
</script>
