<template>
  <v-card>
    <v-toolbar dark color="primary" class="primaryText--text">
      <v-toolbar-title>Account</v-toolbar-title>
      <v-spacer></v-spacer>
    </v-toolbar>
    <v-card-text>
      <v-form ref="form" v-model="valid" lazy-validation>
        <account-selector required></account-selector>
        <stations @station-change="setStation($event)"></stations>
      </v-form>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-col cols="4" xl="3" lg="4" md="4" xs="12">
        <v-btn block color="accent" @click.native="signOut()">sign out</v-btn>
      </v-col>
      <v-col cols="8" xl="6" lg="8" md="8" xs="12">
        <v-btn
          block
          color="primary"
          class="primaryText--text"
          :disabled="!valid"
          @click.native="next"
          >next</v-btn
        >
      </v-col>
    </v-card-actions>
  </v-card>
</template>

<script>
import {mapActions, mapGetters} from 'vuex'
import permissionsMixin from '../mixins/permissions'
import AccountSelector from '../components/auth/AccountSelector'
import Stations from '../components/form/Stations'

const NBC_NETWORK_SKYPATH = '2709081577'

export default {
  name: 'AccountSelect',
  mixins: [permissionsMixin],
  data: () => ({
    valid: false,
    account: '',
    station: '',
    stationRules: [(v) => v.length > 0 || 'Station is required']
  }),
  computed: {
    ...mapGetters('auth', ['getAccountID'])
  },
  methods: {
    ...mapActions('auth', ['signOut']),
    ...mapActions('station', ['setStation']),
    next() {
      if (this.$refs.form.validate()) {
        this.setPermissions()
        this.route('home')
      }
    },
    route(link) {
      if (this.getAccountID === NBC_NETWORK_SKYPATH){
          this.$router.push({
            name: 'schedule'
          })
        } else {
        this.$router.push({
            name: link
          })
      }
    }
  },
  mounted() {
    this.$refs.form.resetValidation()
  },
  components: {
    AccountSelector,
    Stations
  }
}
</script>
