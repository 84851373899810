import actions from './actions'
import mutations from './mutations'
import getters from './getters'

const state = {
    station: '',
    stations: [],
    stationsEmptyState: false,
    stationNames: [],
    tasksStation: [],
    rulesStation: [],
    scheduleStation: '',
    scheduleOverridesStation: [],
    settingsStation: '',
    notificationsStation: [],
    settingsEmails: [],
    apiRoute: 'stations'
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}