<template>
  <div>
    <v-row align="end" justify="start" v-if="!getCanEdit">
      <v-col>
        <p class="body-1 text--secondary">This rule is read-only for user.</p>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <stations
          multiple
          :station="object.stations"
          @station-change="
            object.stations = $event
            update
          "
        ></stations>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" sm="10">
        <v-text-field
          v-model="object.show"
          :value="obj.show"
          :disabled="disabledShow"
          @keydown="update"
          :rules="showRules"
          label="Show"
          require
        ></v-text-field>
      </v-col>
      <v-col cols="12" sm="2" class="pl-0">
        <v-checkbox
          v-model="object.series"
          :value="obj.series"
          @change="update"
          :rules="rules.series"
          label="Series"
          :false-value="false"
          :true-value="true"
        ></v-checkbox>
      </v-col>
    </v-row>
    <v-row v-if="!object.series">
      <v-col cols="12" sm="6" v-if="!object.series">
        <v-text-field
          v-model="object.season"
          :value="obj.season"
          :rules="rules.season"
          @keydown="update"
          label="Season"
        ></v-text-field>
      </v-col>
      <v-col cols="12" sm="6" v-if="!object.series">
        <v-text-field
          v-model="object.episode"
          :value="obj.episode"
          :rules="rules.episode"
          @keydown="update"
          label="Episode"
        ></v-text-field>
      </v-col>
    </v-row>
    <div class="d-flex justify-space-between">
      <v-card
        tile
        flat
        style="width: 80px"
        v-show="getAccountRoles.authKill || getAccountRoles.authKillReadOnly"
      >
        <v-tooltip top>
          <template v-slot:activator="{on, attrs}">
            <p v-bind="attrs" v-on="on" class="body-1 text--secondary">
              AuthKill
            </p>
          </template>
          <span>Authentication Kill</span>
        </v-tooltip>
        <v-switch
          :disabled="
            getAccountRoles.authKillReadOnly && !getAccountRoles.authKill
          "
          v-model="object.authkill"
          :value="obj.authkill"
          @change="update"
          :label="object.authkill | isEnabled"
          :false-value="false"
          :true-value="true"
        ></v-switch>
      </v-card>
      <v-card tile flat style="width: 80px" v-show="getAccountRoles.uhd">
        <v-tooltip top>
          <template v-slot:activator="{on, attrs}">
            <p v-bind="attrs" v-on="on" class="body-1 text--secondary">UHD</p>
          </template>
          <span>Ultra High Definition</span>
        </v-tooltip>
        <v-switch
          v-model="object.takeuhd"
          :value="obj.takeuhd"
          @change="update"
          :label="object.takeuhd | isEnabled"
          :false-value="false"
          :true-value="true"
        ></v-switch>
      </v-card>
      <v-card tile flat style="width: 80px" v-show="getAccountRoles.embargo">
        <p class="body-1 text--secondary">Embargo</p>
        <v-switch
          v-model="object.embargo"
          :value="obj.embargo"
          @change="update"
          :label="object.embargo | isEnabled"
          :false-value="false"
          :true-value="true"
        ></v-switch>
      </v-card>
      <v-card tile flat style="width: 80px" v-show="getAccountRoles.startOver">
        <p class="body-1 text--secondary">Startover</p>
        <v-switch
          v-model="object.startover"
          :value="obj.startover"
          @change="update"
          :label="object.startover | isEnabled"
          :false-value="false"
          :true-value="true"
        ></v-switch>
      </v-card>
      <v-card tile flat style="width: 80px" v-show="getAccountRoles.lookBack">
        <p class="body-1 text--secondary">Lookback</p>
        <v-switch
          v-model="object.lookback"
          :value="obj.lookback"
          @change="update"
          :label="object.lookback | isEnabled"
          :false-value="false"
          :true-value="true"
        ></v-switch>
      </v-card>
    </div>
  </div>
</template>

<script>
import {mapGetters} from 'vuex'
import Stations from './Stations'
import stationsMixin from '../../mixins/stations'
import _ from 'lodash'

export default {
  name: 'RulesDialog',
  mixins: [stationsMixin],
  props: {
    obj: {
      type: Object,
      default: () => ({})
    },
    disabledShow: {
      type: Boolean,
      default: () => false
    },
    disabledStation: {
      type: Boolean,
      default: () => false
    }
  },
  data: () => ({
    noStations: 'No stations available',
    showRules: [(v) => !!v || 'Show is required'],
    stationRules: [(v) => (v && v.length > 0) || 'Station is required'],
    object: {
      stations: [],
      id: '',
      show: '',
      series: false,
      season: '',
      episode: '',
      authkill: false,
      embargo: false,
      startover: false,
      lookback: false,
      uhdavailable: false,
      takeuhd: false
    }
  }),
  computed: {
    ...mapGetters('auth', ['getCanEdit', 'getAccountRoles']),
    rules() {
      const seriesValid =
        this.object.series === true || this.object.season !== ''
      const seasonValid =
        this.object.season !== '' && this.object.series === false
      const episodeValid =
        this.object.season !== '' && this.object.series === false
      return {
        series: [
          () => seriesValid || 'Must provide season or both season and episode.'
        ],
        season: [() => seasonValid || 'Must provide a season'],
        episode: [() => episodeValid || 'Must provide a season.']
      }
    },
    selectedAllStations() {
      return this.object.stations.length === this.stations.length
    },
    selectedSomeStations() {
      return this.object.stations.length > 0 && !this.selectedAllStations
    },
    icon() {
      if (this.selectedAllStations) return 'mdi-checkbox-marked'
      if (this.selectedSomeStations) return 'mdi-minus-box'
      return 'mdi-checkbox-blank-outline'
    }
  },
  methods: {
    toggle() {
      this.$nextTick(() => {
        if (this.selectedAllStations) {
          this.object.stations = []
        } else {
          this.object.stations = this.stations.slice()
        }
      })
    },
    update() {
      this.$emit('rulesChange', this.object)
    }
  },
  watch: {
    obj(val) {
      this.object = val
    }
  },
  mounted() {
    this.object = _.cloneDeep(this.obj)
  },
  components: {
    Stations
  }
}
</script>
