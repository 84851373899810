<template>
  <section>
    <v-row v-if="isUIReady" class="secondary pt-1">
      <v-col class="pb-0">
        <account-selector dense @change="setPermissions"></account-selector>
      </v-col>
      <v-col class="pb-0">
        <stations
          dense
          :station="getScheduleStation"
          @station-change="setScheduleStation($event)"
        ></stations>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-card flat>
          <v-card-title class="pr-0 pl-0 pb-0">
            <v-row flat dense>
              <v-col class="d-flex align-center">
                <h1 class="display-1 primary--text font-weight-bold">
                  Schedule
                  <span
                    class="caption text-right primary--text font-weight-bold"
                    v-if="!getCanEdit"
                  >
                    (Read-Only)</span
                  >
                </h1>
              </v-col>
            </v-row>
          </v-card-title>
          <v-sheet height="64">
            <v-toolbar flat color="white">
              <v-btn
                outlined
                class="mr-4"
                color="grey darken-2"
                @click.native="setToday"
              >
                Today
              </v-btn>
              <v-btn
                aria-label="previous"
                fab
                text
                small
                color="grey darken-2"
                @click.native="prev"
              >
                <v-icon medium>mdi-chevron-left</v-icon>
              </v-btn>
              <v-btn
                aria-label="next"
                fab
                text
                small
                color="grey darken-2"
                @click.native="next"
              >
                <v-icon medium>mdi-chevron-right</v-icon>
              </v-btn>
              <v-toolbar-title>{{ title }}</v-toolbar-title>
              <v-spacer></v-spacer>
              <v-sheet
                class="mr-2"
                height="20"
                width="40"
                :color="colors.rightsSet.background"
              ></v-sheet>
              <span v-if="getAccountID === NBC_NETWORK_SKYPATH" class="mr-10">Schedule Overrides Set</span>
              <span v-else class="mr-10">Rights Set</span>              
              <v-sheet
                class="mr-2"
                height="20"
                width="40"
                :color="colors.rightsNotSet.background"
              ></v-sheet>
              <span v-if="getAccountID === NBC_NETWORK_SKYPATH" class="mr-10">No Schedule Overrides Set</span>
              <span v-else class="mr-10">No Rights Set</span>
              <v-menu bottom right>
                <template #activator="{on}">
                  <v-btn outlined color="grey darken-2" v-on="on">
                    <span>{{ typeToLabel[key].label }}</span>
                    <v-icon right>mdi-menu-down</v-icon>
                  </v-btn>
                </template>
                <v-list v-for="(val, key, i) in typeToLabel" :key="i">
                  <v-list-item @click.native="updateDayRange(key, val)">
                    <v-list-item-title>{{ val.label }}</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </v-toolbar>
          </v-sheet>
          <v-sheet>
            <v-calendar
              ref="calendar"
              v-model="focus"
              event-overlap-mode="stack"
              color="primary"
              :events="events"
              :event-color="getEventColor"
              :event-text-color="getEventTextColor"
              :type="type"
              :start="today"
              :now="today"
              :max-days="maxDays"
              :weekdays="weekdays"
              :first-interval="0"
              :interval-minutes="15"
              :interval-format="intervalFormat"
              :interval-count="96"
              @click:event="showEvent"
              @click:date="viewDay"
              @change="updateRange"
            >
              <template #event="{event}">
                <v-row
                  v-if="event.details.rights && getAccountID != NBC_NETWORK_SKYPATH"
                  class="no-gutters flex-wrap"
                  :color="
                    $vuetify.theme.dark ? 'grey darken-3' : 'grey lighten-4'
                  "
                >
                  <v-col
                    v-if="getAccountRoles.authKill"
                    :class="event.rightsDetails.authKill.class"
                    cols="auto"
                    class="grow pl-1"
                  >
                    <v-icon :class="event.rightsDetails.authKill.class" small>{{
                      event.rightsDetails.authKill.icon
                    }}</v-icon>
                    <span class="eventRules pl-1"> AuthKill</span>
                  </v-col>
                  <v-col
                    v-if="event.rightsDetails.takeuhd && getAccountRoles.uhd"
                    :class="event.rightsDetails.takeuhd.class"
                    cols="auto"
                    class="grow pl-1"
                  >
                    <v-icon :class="event.rightsDetails.takeuhd.class" small>{{
                      event.rightsDetails.takeuhd.icon
                    }}</v-icon>
                    <span class="eventRules pl-1"> TakeUHD</span>
                  </v-col>
                  <v-col
                    v-if="getAccountRoles.embargo"
                    :class="event.rightsDetails.embargo.class"
                    cols="auto"
                    class="grow px-1"
                  >
                    <v-icon :class="event.rightsDetails.embargo.class" small>{{
                      event.rightsDetails.embargo.icon
                    }}</v-icon>
                    <span class="eventRules pl-1"> Embargo</span>
                  </v-col>

                  <v-col
                    v-if="getAccountRoles.startOver"
                    :class="event.rightsDetails.startOver.class"
                    cols="auto"
                    class="grow px-1"
                  >
                    <v-icon
                      :class="event.rightsDetails.startOver.class"
                      small
                      >{{ event.rightsDetails.startOver.icon }}</v-icon
                    >
                    <span class="eventRules pl-1"> Startover</span>
                  </v-col>

                  <v-col
                    v-if="getAccountRoles.lookBack"
                    :class="event.rightsDetails.lookBack.class"
                    cols="auto"
                    class="grow px-1"
                  >
                    <v-icon :class="event.rightsDetails.lookBack.class" small>{{
                      event.rightsDetails.lookBack.icon
                    }}</v-icon>
                    <span class="eventRules pl-1"> Lookback</span>
                  </v-col>
                </v-row>

                <v-row class="ma-0">
                  <v-col cols="auto" class="pt-1 pb-0 pl-1">
                    <p class="pl-1 pb-0 mb-0 mt-0">
                      <strong>{{ event.name }}</strong>
                    </p>
                  </v-col>
                </v-row>
                <v-row v-if="getAccountID === NBC_NETWORK_SKYPATH" class="ma-0">
                  <v-col cols="auto" class="pt-1 pb-0 pl-1">
                    <p class="pl-1 pb-0 mb-0 mt-0">
                      <strong>{{ event.identifier }}</strong>
                    </p>
                  </v-col>
                </v-row>
                <v-row class="ma-0">
                  <v-col cols="auto" class="pt-1 pb-0 pl-1">
                    <p class="pl-1 pb-0 mb-0 mt-0">
                      {{ getEventDisplayTime(event) }}
                    </p>
                  </v-col>
                </v-row>
              </template>
            </v-calendar>
            <v-menu
              max-width="600"
              v-model="selectedOpen"
              :close-on-content-click="false"
              :activator="selectedElement"
              eager
              offset-x
            >
              <v-card min-width="600" outlined raised flat>
                <v-toolbar dark :color="selectedCalendarEvent.colorOrginal">
                  <v-toolbar-title
                    :class="selectedCalendarEvent.class"
                    v-html="selectedCalendarEvent.name"
                  ></v-toolbar-title>
                  <v-spacer></v-spacer>
                  <v-btn
                    icon
                    dark
                    @click.native="
                      selectedOpen = false
                      close(selectedCalendarEvent)
                    "
                  >
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                </v-toolbar>
                <schedule-overrides-events-form v-if="getAccountID === NBC_NETWORK_SKYPATH" ref="eventsForm" :view="scheduleview" @changeSOHeaderColor="changeSOHeaderColor" @requestEventsOnSave="requestEventsOnSave"></schedule-overrides-events-form>
                <rights-events-form v-else ref="rightsEventsForm"></rights-events-form>
              </v-card>
            </v-menu>
          </v-sheet>
        </v-card>
      </v-col>
    </v-row>
  </section>
</template>

<script>
import {DateTime} from 'luxon'
import {mapGetters, mapActions} from 'vuex'
import ScheduleOverridesEventsForm from '../components/form/ScheduleOverridesEvents'
import RightsEventsForm from '../components/form/RightsEvents'
import AccountSelector from '../components/auth/AccountSelector'
import Stations from '../components/form/Stations'
import permissionsMixin from '../mixins/permissions'
import {SnackBus} from '../main'
import _ from 'lodash'

export default {
  name: 'Schedule',
  mixins: [permissionsMixin],
  data: () => ({
    focus: '',
    type: 'week',
    typeLabel: 'week',
    key: 'week',
    valid: false,
    syncInterval: null,
    defaultsApplied: false,
    showDefaultsButton: false,
    startorend : 'start',
    typeToLabel: {
      week: {
        label: 'Week',
        type: 'week',
        maxDays: 7
      },
      '4day': {
        label: '4 Days',
        type: '4day',
        maxDays: 4
      },
      day: {
        label: 'Day',
        type: 'day',
        maxDays: 1
      }
    },
    format: 'yyyy-MM-dd HH:mm',
    maxDays: 7,
    start: null,
    end: null,
    selectedEventColor: 'secondary',
    selectedElement: null,
    selectedCalendarEvent: {
      id: '',
      name: '',
      start: '',
      end: '',
      originalEvent: null,
      details: null,
      textColor: '',
      class: '',
      colorOrginal: '',
      color: '',
      rightsDetails: null
    },
    selectedOpen: false,
    events: [
      {
        start: DateTime.local().toISODate(),
        end: DateTime.local().toISODate(),
        details: {}
      }
    ],
    weekdays: [1, 2, 3, 4, 5, 6, 0],
    colors: {
      rightsSet: {
        background: '#6DA686',
        text: 'black',
        class: 'black--text'
      },
      rightsNotSet: {
        background: '#D9A998',
        text: 'grey darken-4',
        class: 'grey--text text--darken-4'
      },
      schedOverridesSet: {
        background: '#6DA686',
        text: 'black',
        class: 'black--text'
      },
      schedOverridesNotSet: {
        background: '#D9A998',
        text: 'grey darken-4',
        class: 'grey--text text--darken-4'
      }
    },
    rights: {
      takeuhd: {
        enabled: {
          icon: 'mdi-ultra-high-definition',
          class: 'accent white--text'
        },
        disabled: {
          icon: 'mdi-ultra-high-definition',
          class: 'grey lighten-2 black--text'
        }
      },
      authKill: {
        enabled: {
          icon: 'mdi-lock',
          class: 'accent white--text'
        },
        disabled: {
          icon: 'mdi-lock-off',
          class: 'grey lighten-2 black--text'
        }
      },
      embargo: {
        enabled: {
          icon: 'cloud',
          class: 'accent white--text'
        },
        disabled: {
          icon: 'cloud_off',
          class: 'grey lighten-2 black--text'
        }
      },
      startOver: {
        enabled: {
          icon: 'sync',
          class: 'accent white--text'
        },
        disabled: {
          icon: 'sync_disabled',
          class: 'grey lighten-2 black--text'
        }
      },
      lookBack: {
        enabled: {
          icon: 'visibility',
          class: 'accent white--text'
        },
        disabled: {
          icon: 'visibility_off',
          class: 'grey lighten-2 black--text'
        }
      }
    },
    NBC_NETWORK_SKYPATH: '2709081577',
    scheduleview : 'Schedule'
  }),

  asyncComputed: {
    evts: {
      lazy: true,
      get() {
        return this.requestEvents({
          station: this.getScheduleStation,
          account: this.getAccountID,
          token: this.getToken,
          from: DateTime.fromISO(this.start.date)
            .setZone(this.getTimezone)
            .minus({days: 1})
            .toUTC()
            .toISODate(),
          to: DateTime.fromISO(this.end.date)
            .setZone(this.getTimezone)
            .plus({days: 2})
            .toUTC()
            .toISODate()
        })
          .then(() => {
            this.updateRange({start: this.start, end: this.end})
          })
          .catch(() => {
            SnackBus.$emit('error', 'Error getting events')
            this.events = []
          })
      },
      default: []
    }
  },
  computed: {
    ...mapGetters('auth', [
      'getCanEdit',
      'getAccountID',
      'getToken',
      'getAccountRoles'
    ]),
    ...mapGetters('app', ['isUIReady']),
    ...mapGetters('event', ['getEvents']),
    ...mapGetters('station', ['getStationNames', 'getScheduleStation']),
    ...mapGetters('time', ['getTimezone']),

    title() {
      const {start, end} = this
      if (!start || !end) {
        return ''
      }

      const startMonth = this.monthFormatter(start)
      const endMonth = this.monthFormatter(end)
      const suffixMonth = startMonth === endMonth ? '' : endMonth

      const startYear = start.year
      const endYear = end.year
      const suffixYear = startYear === endYear ? '' : endYear

      const startDay = start.day + this.nth(start.day)
      const endDay = end.day + this.nth(end.day)

      switch (this.type) {
        case 'week':
        case '4day':
          return `${startMonth} ${startDay} ${startYear} - ${suffixMonth} ${endDay} ${suffixYear}`
        case 'day':
          return `${startMonth} ${startDay} ${startYear}`
      }
      return ''
    },
    monthFormatter() {
      return this.$refs.calendar.getFormatter({
        timeZone: this.timeZone,
        month: 'long'
      })
    },
    today() {
      return DateTime.local().setZone(this.getTimezone).toISODate()
    }
  },
  mounted() {
    this.sync()
    // every 30 seconds
    this.syncInterval = setInterval(this.sync, 10000)
  },
  beforeDestroy() {
    if (this.syncInterval) {
      clearInterval(this.syncInterval)
      this.syncInterval = null
    }
  },
  watch: {
    getTimezone() {
      this.updateRange({start: this.start, end: this.end})
    },
    getScheduleStation() {
      this.$asyncComputed.evts.update()
    },
    selectedOpen(val) {
      if (!val) {
        this.close(this.selectedCalendarEvent)
      }

      this.updateRange({start: this.start, end: this.end})

      return val
    }
  },
  methods: {
    ...mapActions('event', ['requestEvents']),
    ...mapActions('station', ['setScheduleStation']),
    ...mapActions('event', ['updateEvent']),
    intervalFormat(interval) {
      return interval.time
    },
    sync() {
      this.$asyncComputed.evts.update()
    },
    changeSOHeaderColor(startorend){
      this.startorend = startorend
      if(startorend == 'start'){
        var startoverridden = this.selectedCalendarEvent.details.scheduleoverridestate.startoverridden
         if(startoverridden == true){
            this.selectedCalendarEvent.colorOrginal = this.colors.schedOverridesSet.background 
         }else if(startoverridden == false){
            this.selectedCalendarEvent.colorOrginal = this.colors.schedOverridesNotSet.background 
         }
        
      }else if(startorend == 'end'){
        var endoverridden = this.selectedCalendarEvent.details.scheduleoverridestate.endoverridden
         if( endoverridden == true){
            this.selectedCalendarEvent.colorOrginal = this.colors.schedOverridesSet.background 
         }else if(endoverridden == false){
            this.selectedCalendarEvent.colorOrginal = this.colors.schedOverridesNotSet.background 
         }
        
      }  
    },
    requestEventsOnSave(){
      this.$asyncComputed.evts.update()
    },
    updateDayRange(key, val) {
      // If the day range doesn't change return
      if (this.key == key) return

      this.type = val.type
      this.maxDays = val.maxDays
      this.typeLabel = val.label
      this.key = key

      // When the visible events change request events in that window.
      const visibleEvents = this.$refs.calendar.getVisibleEvents()
      if (visibleEvents.length > 0) {
        this.start = visibleEvents[0].start
        this.end = visibleEvents[visibleEvents.length - 1].end
      }

      setTimeout(() => this.sync(), 10)
    },
    viewDay({date}) {
      this.focus = date
      this.maxDays = 1
      this.typeLabel = 'day'
      this.key = 'day'
      this.type = 'day'
    },
    getEventColor(event) {
      return event.color
    },
    getEventTextColor(event) {
      return event.textColor
    },
    getEventDisplayTime(e) {
      // Submitted a Issue to LUXON for the formatting issue. https://github.com/moment/luxon/issues/726
      const start = DateTime.fromFormat(e.start, 'yyyy-MM-dd HH:mm').toFormat(
        'HH:mm'
      )
      const end = DateTime.fromFormat(e.end, 'yyyy-MM-dd HH:mm').toFormat(
        'HH:mm'
      )
      return `${start} - ${end}`
    },
    setToday() {
      this.focus = this.today
      setTimeout(() => this.sync(), 10)
    },
    prev() {
      setTimeout(() => this.$refs.calendar.prev(), 10)
      setTimeout(() => this.sync(), 10)
    },
    next() {
      setTimeout(() => this.$refs.calendar.next(), 10)
      setTimeout(() => this.sync(), 10)
    },
    async showEvent({nativeEvent, event}) {
      this.selectedCalendarEvent = event
      this.selectedCalendarEvent.color = this.selectedEventColor
      this.defaultsApplied = false
      this.showDefaultsButton = false
      this.selectedElement = nativeEvent.target

      setTimeout(() => (this.selectedOpen = true), 10)

      let obj
      if (this.getAccountID === this.NBC_NETWORK_SKYPATH ) {
        obj = await this.$refs.eventsForm.open(event.details, event.identifier)
      } else {
        obj = await this.$refs.rightsEventsForm.open(event.details)
      }

      if (_.isEmpty(obj)) {
        this.selectedOpen = false
        return
      }
      this.save(obj)
      nativeEvent.stopPropagation()
    },
    updateRange({start, end}) {
      this.events = []
      this.start = start
      this.end = end

      if (this.getEvents === null) {
        return []
      }

      for (let i = 0; i < this.getEvents.length; i++) {
        const event = this.getEvents[i]
        const {
          showtitle,
          season,
          episode,
          start,
          end,
          rights,
          takeuhd,
          uhdavailable,
          authkill,
          embargo,
          startover,
          lookback,
          scheduleoverridestate
        } = event

        const eventStart = DateTime.fromISO(start)
          .setZone(this.getTimezone)
          .toFormat(this.format)
        const eventEnd = DateTime.fromISO(end)
          .setZone(this.getTimezone)
          .toFormat(this.format)
        const color = rights ? this.colors.rightsSet : this.colors.rightsNotSet
        const schedColor =  scheduleoverridestate?.startoverridden || scheduleoverridestate?.endoverridden ? this.colors.schedOverridesSet : this.colors.schedOverridesNotSet
        const uhd =
          takeuhd && rights
            ? this.rights.takeuhd.enabled
            : this.rights.takeuhd.disabled
        const authk =
          authkill && rights
            ? this.rights.authKill.enabled
            : this.rights.authKill.disabled
        const emb =
          embargo && rights
            ? this.rights.embargo.enabled
            : this.rights.embargo.disabled
        const lookB =
          lookback && rights
            ? this.rights.lookBack.enabled
            : this.rights.lookBack.disabled
        const startO =
          startover && rights
            ? this.rights.startOver.enabled
            : this.rights.startOver.disabled

        const rightsDetails = {
          authKill: authk,
          embargo: emb,
          lookBack: lookB,
          startOver: startO
        }

        if (uhdavailable) {
          rightsDetails['takeuhd'] = uhd
        }

        let eventName = showtitle
        if (season !== '' || episode !== '') {
          eventName = `${showtitle} - S[${season}] E[${episode}]`
        }

        let calendarEvent = {
          id: event.guid,
          identifier: event.guid.split('/')[4],
          name: eventName,
          start: eventStart,
          end: eventEnd,
          originalEvent: Object.assign({}, event),
          details: Object.assign({}, event),
          textColor: this.getAccountID === this.NBC_NETWORK_SKYPATH ? schedColor.text : color.text,
          class:  this.getAccountID === this.NBC_NETWORK_SKYPATH ? schedColor.class : color.class,
          colorOrginal: this.getAccountID === this.NBC_NETWORK_SKYPATH ? (this.selectedCalendarEvent.id == event.guid) && (this.startorend  == 'end' && scheduleoverridestate?.endoverridden) ? this.colors.schedOverridesSet.background :  (this.selectedCalendarEvent.id == event.guid) && (this.startorend  == 'start' && scheduleoverridestate?.startoverridden) ? this.colors.schedOverridesSet.background : this.colors.schedOverridesNotSet.background: color.background,
          color: this.getAccountID === this.NBC_NETWORK_SKYPATH ? schedColor.background : color.background,
          rightsDetails: rightsDetails
        }
        if (
          this.selectedCalendarEvent.id === calendarEvent.id &&
          this.selectedOpen
        ) {
          // sets the details of the active selected event to the matched event.
          calendarEvent.details = this.selectedCalendarEvent.details
          calendarEvent.color = this.selectedEventColor
          this.selectedCalendarEvent = calendarEvent
        }

        this.events.push(calendarEvent)
      }
    },

    save(e) {
      if (this.getAccountID != this.NBC_NETWORK_SKYPATH) {
        this.updateEvent({
          station: this.getScheduleStation,
          account: this.getAccountID,
          token: this.getToken,
          data: [e]
        })
          .then(() => {
            SnackBus.$emit('open', {
              text: 'Successfully updated event',
              icon: 'check',
              color: 'green'
            })
          })
          .catch(() => {
            SnackBus.$emit('error', 'Error updating event')
          })
      }
      this.selectedOpen = false
      this.sync()
    },
    close(e) {
      e.color = e.colorOrginal
    },
    nth(d) {
      return d > 3 && d < 21
        ? 'th'
        : ['th', 'st', 'nd', 'rd', 'th', 'th', 'th', 'th', 'th', 'th'][d % 10]
    }
  },
  components: {
    ScheduleOverridesEventsForm,
    RightsEventsForm,
    Stations,
    AccountSelector
  }
}
</script>

<style>
.v-overflow-btn .v-input__slot::before {
  border-color: grey !important;
}
.eventRules {
  font-size: 0.65rem !important;
  letter-spacing: 0.0333333333em !important;
  line-height: 1.25rem;
}
</style>
