import {SET_TIMEZONE, CLEAR} from '../../types'
import {DateTime} from 'luxon'

export default {
  [SET_TIMEZONE](state, {timezone}) {
    state.timezone = timezone
  },
  [CLEAR](state) {
    state.timezone = DateTime.local().zoneName
  }
}
