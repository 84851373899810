// App
export const SET_UI_READY = 'SET_UI_READY'

// Time
export const SET_TIMEZONE = 'SET_TIMEZONE'

// Event
export const SET_EVENTS = 'SET_EVENTS'

// Tasks
export const SET_TASKS = 'SET_TASKS'
export const SET_TASKS_FROM = 'SET_TASKS_FROM'
export const SET_TASKS_TO = 'SET_TASKS_TO'
    // Rule
export const SET_RULES = 'SET_RULES'

// Schedule Overrides
export const SET_SCHEDULE_OVERRIDES = 'SET_SCHEDULE_OVERRIDES'
    // Schedule Override
export const SET_SCHEDULE_OVERRIDE = 'SET_SCHEDULE_OVERRIDE'
    //Event or Schedule override
export const SET_EVENT_OR_SO = 'SET_EVENT_OR_SO'


// Emails
export const SET_EMAILS = 'SET_EMAILS'

// Notifications
export const SET_NOTIFICATIONS = 'SET_NOTIFICATIONS'

// User
export const SET_USERNAME = 'SET_USERNAME'
export const SET_USERID = 'SET_USERID'
export const SET_ACCOUNT = 'SET_ACCOUNT'
export const SET_ACCOUNTNAME = 'SET_ACCOUNTNAME'
export const SET_ACCOUNTID = 'SET_ACCOUNTID'
export const SET_ACCOUNTS = 'SET_ACCOUNTS'
export const SET_ACCOUNT_ROLES = 'SET_ACCOUNT_ROLES'
export const SET_TOKEN = 'SET_TOKEN'
export const SET_ROLE = 'SET_ROLE'
export const SET_READ_ONLY = 'SET_READ_ONLY'
export const SET_ROLE_TITLE = 'SET_ROLE_TITLE'
export const SET_ISAUTHENTICATED = 'SET_ISAUTHENTICATED'
export const SET_SIGNOUT = 'SET_SIGNOUT'

export const SET_STATION = 'SET_STATION'
export const SET_STATIONS = 'SET_STATIONS'
export const SET_STATIONS_STATE_EMPTY = 'SET_STATIONS_STATE_EMPTY'
export const SET_TASKS_STATION = 'SET_TASKS_STATION'
export const SET_RULES_STATION = 'SET_RULES_STATION'
export const SET_SCHEDULE_STATION = 'SET_SCHEDULE_STATION'
export const SET_SCHEDULE_OVERRIDES_STATION = 'SET_SCHEDULE_OVERRIDES_STATION'
export const SET_SETTINGS_STATION = 'SET_SETTINGS_STATION'
export const SET_NOTIFICATIONS_STATION = 'SET_NOTIFICATIONS_STATION'

export const CLEAR = 'CLEAR'
export const SET_ALL_STATIONS = 'SET_ALL_STATIONS'

export const API_BASE = '/proxy/v1/affiliate/'

export default {
    // App
    SET_UI_READY,

    // Time
    SET_TIMEZONE,

    // Event
    SET_EVENTS,

    // Tasks
    SET_TASKS,
    SET_TASKS_FROM,
    SET_TASKS_TO,

    // Rule
    SET_RULES,

    // Schedule Overrides
    SET_SCHEDULE_OVERRIDES,
    // Schedule Override
    SET_SCHEDULE_OVERRIDE,
    // Event or Schedule Override
    SET_EVENT_OR_SO,

    // Settings
    SET_EMAILS,

    // Notifications
    SET_NOTIFICATIONS,

    // User
    SET_USERID,
    SET_USERNAME,
    SET_ACCOUNT,
    SET_ACCOUNTNAME,
    SET_ACCOUNTID,
    SET_ACCOUNTS,
    SET_ACCOUNT_ROLES,
    SET_TOKEN,
    SET_ROLE,
    SET_ROLE_TITLE,
    SET_READ_ONLY,
    SET_ISAUTHENTICATED,

    // Station
    SET_STATION,
    SET_STATIONS,
    SET_STATIONS_STATE_EMPTY,
    SET_TASKS_STATION,
    SET_RULES_STATION,
    SET_SCHEDULE_STATION,
    SET_SCHEDULE_OVERRIDES_STATION,
    SET_SETTINGS_STATION,

    API_BASE,
    CLEAR,
    SET_ALL_STATIONS
}