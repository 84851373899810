<template>
  <section>
    <v-row v-if="isUIReady" class="secondary pt-1">
      <v-col class="pb-0">
        <account-selector dense @change="setPermissions"></account-selector>
      </v-col>
      <v-col class="pb-0">
        <stations
          dense
          multiple
          :station="getNotificationsStation"
          @station-change="
            loading = true
            setNotificationsStation($event)
          "
        ></stations>
      </v-col>
    </v-row>
    <v-card flat class="mt-3">
      <v-card-title class="pr-0 pl-0 pb-0">
        <v-row flat dense>
          <v-col class="d-flex align-center">
            <h1 class="display-1 primary--text font-weight-bold">
              Notifications
            </h1>
          </v-col>
          <v-spacer></v-spacer>
          <v-col cols="12" lg="4" class="pl-4">
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Search"
              single-line
              clearable
              hide-details
            ></v-text-field>
          </v-col>
          <v-col cols="12" lg="2" dense>
            <v-menu
              ref="notificationdatepicker"
              v-model="datePickerOpen"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              max-width="290px"
              min-width="290px"
            >
              <template v-slot:activator="{on}">
                <v-text-field
                  v-model="datePickerFormatted"
                  label="Date"
                  hint="MM/DD/YYYY format"
                  append-icon="mdi-calendar"
                  persistent-hint
                  class="pl-3"
                  readonly
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="date"
                @change="datapickerChange"
                no-title
                @input="datePickerOpen = false"
              >
                <v-spacer></v-spacer>
                <v-btn color="primary" class="primaryText--text" @click="today"
                  >Today</v-btn
                >
              </v-date-picker>
            </v-menu>
          </v-col>
        </v-row>
      </v-card-title>
      <v-data-table
        :headers="headers"
        item-key="guid"
        :items="notifications"
        :search="search"
        no-results-text="No matching notifications found."
        no-data-text="No notifications found. Maybe a station needs to be selected"
        loading-text="Loading notifications..."
        :loading="loading"
      >
        <template #[`item.station`]="{item}">
          {{ item.station.toUpperCase() | fillEmpty }}
        </template>
        <template #[`item.notes`]="{item}">
          {{ item.notes | fillEmpty }}
        </template>
        <template #[`item.startTime`]="{item}">
          {{ item.startTime | userTime }}
        </template>
        <template #[`item.endTime`]="{item}">
          {{ item.endTime | userTime }}
        </template>
      </v-data-table>
    </v-card>
  </section>
</template>

<script>
import {DateTime} from 'luxon'
import {mapGetters, mapActions} from 'vuex'
import Stations from '../components/form/Stations'
import AccountSelector from '../components/auth/AccountSelector'
import permissionsMixin from '../mixins/permissions'
import {SnackBus} from '../main'

export default {
  name: 'Notifications',
  mixins: [permissionsMixin],
  data: () => ({
    dialog: false,
    loading: true,
    search: '',
    date: DateTime.local().startOf('day').toISODate(),
    from: DateTime.local().startOf('day').toString(),
    to: DateTime.local().endOf('day').toString(),
    datePickerOpen: false,
    syncInterval: null,
    stations: [],
    headers: [
      {
        text: 'Station',
        align: 'start',
        value: 'station'
      },
      {text: 'Airring ID', value: 'scheduledAiringId'},
      {text: 'Start', value: 'startTime'},
      {text: 'End', value: 'endTime'},
      {text: 'Notes', value: 'notes'}
    ]
  }),
  computed: {
    ...mapGetters('auth', ['getCanEdit', 'getAccountID', 'getToken']),
    ...mapGetters('app', ['isUIReady']),
    ...mapGetters('station', [
      'getStation',
      'getNotificationsStation',
      'getStationNames'
    ]),
    ...mapGetters('notification', ['getNotifications']),
    formTitle() {
      if (this.editedEvent.season !== '' || this.editedEvent.episode !== '') {
        return `${this.editedEvent.showtitle} - S[${this.editedEvent.season}] E[${this.editedEvent.episode}]`
      }
      return this.editedEvent.showtitle
    },
    datePickerFormatted() {
      return this.formatDate(this.date)
    }
  },
  asyncComputed: {
    notifications: {
      get() {
        return this.requestNotifications({
          station: this.getNotificationsStation,
          account: this.getAccountID,
          token: this.getToken,
          from: this.from,
          to: this.to
        })
          .then((notifications) => {
            this.loading = false
            if (notifications.length === 0) return []
            return notifications
          })
          .catch(() => {
            this.loading = false
            SnackBus.$emit('error', 'Error getting notifications')
            return []
          })
      },
      default: []
    }
  },
  mounted() {
    this.sync()
    // Every 30 seconds
    this.syncInterval = setInterval(this.sync, 30000)
  },
  beforeDestroy() {
    if (this.syncInterval) {
      clearInterval(this.syncInterval)
      this.syncInterval = null
    }
  },
  methods: {
    ...mapActions('notification', ['requestNotifications']),
    ...mapActions('station', ['setNotificationsStation']),
    sync() {
      this.$asyncComputed.notifications.update()
    },
    formatDate(date) {
      if (!date) return null

      const [year, month, day] = date.split('-')
      return `${month}/${day}/${year}`
    },
    today() {
      this.date = DateTime.local().startOf('day').toISODate()
      this.from = DateTime.local().startOf('day').toString()
      this.to = DateTime.local().endOf('day').toString()
      this.datePickerOpen = false
    },
    datapickerChange(day) {
      this.date = DateTime.fromISO(day).startOf('day').toISODate()
      ;(this.from = DateTime.fromISO(day).startOf('day').toString()),
        (this.to = DateTime.fromISO(day).endOf('day').toString())
    }
  },
  components: {
    Stations,
    AccountSelector
  }
}
</script>
