<template>
  <div>
    <v-autocomplete
      :no-data-text="noAccounts"
      :search-input.sync="search"
      ref="accountselector"
      :value="getAccountName"
      :items="getAccountNames"
      :rules="accountRules"
      :loading="loading"
      label="Account"
      @change="updateAccount"
      :dense="dense"
      :required="required"
    ></v-autocomplete>
  </div>
</template>

<script>
import _ from 'lodash'
import {mapGetters, mapActions} from 'vuex'
import stationsMixin from '../../mixins/stations'

const NBC_NETWORK_SKYPATH = '2709081577'

export default {
  name: 'AccountSelector',
  mixins: [stationsMixin],
  data: () => ({
    valid: true,
    accountName: '',
    search: '',
    loading: false,
    noAccounts: 'No accounts available',
    accountRules: [(v) => !!v || 'Account is required']
  }),
  props: {
    required: {
      type: Boolean,
      default: () => false
    },
    dense: {
      type: Boolean,
      default: () => false
    }
  },
  mounted() {
    this.sync()
  },
  watch: {
    search() {
      if (this.loading) return
      this.loading = true
      this.debounce()
    }
  },
  computed: {
    ...mapGetters('auth', ['getAccountName', 'getAccountNames', 'getAccounts', 'getAccountID'])
  },
  methods: {
    ...mapActions('auth', [
      'setAccount',
      'setAccountName',
      'setAccountID',
      'requestAccounts'
    ]),
    ...mapActions('station', ['stationClear', 'setStationsStateEmpty']),
    sync() {
      this.requestAccounts({
        search: this.search,
        token: this.getToken
      })
        .then(() => {
          this.loading = false
        })
        .catch(() => {
          this.loading = false
        })
    },
    debounce() {
      clearTimeout(this._timerId)
      this._timerId = setTimeout(() => {
        this.sync()
      }, 1000)
    },
    updateAccount(value) {
      const account = _.find(this.getAccounts, {name: value})
      this.setAccount(account)
      this.setAccountName(account?.name)
      this.setAccountID(account?.id)
      this.stationClear()
      this.setStationsStateEmpty()
      this.$asyncComputed.stations.update()    
      if (!window.location.pathname.includes('/signin')){
        if (this.getAccountID === NBC_NETWORK_SKYPATH){
          this.$router.push({name: 'schedule'})
        }
      }
      this.$emit('change')
    }
  }
}
</script>
