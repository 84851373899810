import axios from 'axios'
import { API_BASE } from '../../types'

const updateScheduleOverride = ({ state, commit }, obj) => {
    const scheduleOverrideData = new Promise((resolve, reject) => {
        const { account, token, data } = obj

        const d = {
            scheduleoverrides: data
        }

        axios
            .put(API_BASE + state.apiRoute, d, {
                params: {
                    token,
                    account
                },
                validateStatus: (status) => status < 400
            })
            .then((response) => {
                let scheduleoverrides = response.data
                if (scheduleoverrides === null || scheduleoverrides.length === 0) {
                    commit('SET_SCHEDULE_OVERRIDES', [])
                    resolve([])
                }

                commit('SET_SCHEDULE_OVERRIDES', scheduleoverrides)
                resolve(scheduleoverrides)
            })
            .catch((error) => {
                reject(error)
            })

        return null
    })

    return scheduleOverrideData
}

const createScheduleOverride = ({ state, commit }, obj) => {
    const scheduleOverrideData = new Promise((resolve, reject) => {
        const { account, token, data } = obj

        const d = {
            scheduleoverrides: data
        }

        axios
            .post(API_BASE + state.apiRoute, d, {
                params: {
                    token,
                    account
                },
                validateStatus: (status) => status < 400
            })
            .then((response) => {
                let scheduleoverrides = response.data

                if (scheduleoverrides === null || scheduleoverrides.length === 0) {
                    commit('SET_SCHEDULE_OVERRIDES', [])
                    resolve([])
                }

                commit('SET_SCHEDULE_OVERRIDES', scheduleoverrides)
                resolve(scheduleoverrides)
            })
            .catch((error) => {
                reject(error)
            })
        return null
    })

    return scheduleOverrideData
}

const deleteScheduleOverrides = ({ state }, obj) => {
    const scheduleOverrideData = new Promise((resolve, reject) => {
        const { account, token, data } = obj

        const d = {
            scheduleoverrides: data
        }

        axios
            .delete(API_BASE + state.apiRoute, {
                params: {
                    token,
                    account
                },
                data: d,
                validateStatus: (status) => status < 400
            })
            .then(() => {
                resolve()
            })
            .catch((error) => {
                reject(error)
            })

        return null
    })

    return scheduleOverrideData
}

const requestScheduleOverrides = ({ commit, state }, obj) => {
    const scheduleoverrides = new Promise((resolve, reject) => {
        const { station, account, token } = obj

        let stations = station
        if (stations.length > 0) {
            stations = station.join(',')
        }

        axios
            .get(API_BASE + state.apiRoute, {
                params: {
                    token,
                    account,
                    station: stations
                },
                validateStatus: (status) => status < 400
            })
            .then((response) => {
                let scheduleoverrides = response.data

                if (scheduleoverrides === null || scheduleoverrides.length === 0) {
                    commit('SET_SCHEDULE_OVERRIDES', [])
                    resolve([])
                    return []
                }

                if (scheduleoverrides.scheduleoverrides === null) {
                    commit('SET_SCHEDULE_OVERRIDES', [])
                    resolve([])
                    return []
                }
                commit('SET_SCHEDULE_OVERRIDES', scheduleoverrides.scheduleoverrides)
                resolve(scheduleoverrides.scheduleoverrides)
            })
            .catch((err) => reject(err))
        return null
    })
    return scheduleoverrides
}

export default {
    requestScheduleOverrides,
    createScheduleOverride,
    updateScheduleOverride,
    deleteScheduleOverrides
}