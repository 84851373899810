import Vue from 'vue'
import App from './App.vue'
import router from './router/router'
import store from './store/index'
import Toasted from 'vue-toasted'
import AsyncComputed from 'vue-async-computed'
import vuetify from './plugins/vuetify'
import 'vuetify/dist/vuetify.min.css'
import 'material-design-icons-iconfont/dist/material-design-icons.css'
import { DateTime } from 'luxon'
import DatetimePicker from './components/form/DateTimePicker'
Vue.config.productionTip = false

Vue.use(Toasted, {
    position: 'top-right',
    duration: 5000,
    containerClass: 'toasted'
})

// register the toast with the custom message
Vue.toasted.register('server_error', (payload) => {
    // if there is no message passed show default message
    if (!payload.message) {
        return 'Oops.. Something Went Wrong..'
    }

    // if there is a message show it with the message
    return 'Oops.. ' + payload.message
})

Vue.filter('isEnabled', (value) => {
    return value ? 'Yes' : 'No'
})

Vue.filter('fillEmpty', (value) => {
    return value ? value : '--'
})

Vue.filter('userTime', (value) => {
    let format = {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        hourCycle: 'h23' // Without this, locale returns 24 for 12AM in 24 hours format
    };
    let dateTime = DateTime.fromISO(value, { zone: store.getters['time/getTimezone'] })
    let dateParts = dateTime.toLocaleParts(format);
    let { offsetNameShort } = dateTime
    let month, year, day, hour, seconds, mins;
    dateParts.forEach((each) => {
        if (each.type === 'month') month = each.value;
        if (each.type === 'year') year = each.value;
        if (each.type === 'day') day = each.value;
        if (each.type === 'hour') hour = each.value;
        if (each.type === 'minute') mins = each.value;
        if (each.type === 'second') seconds = each.value;
    });
    return `${month}/${day}/${year}, ${hour}:${mins}:${seconds} ${offsetNameShort}`;
})

Vue.use(AsyncComputed)

Vue.component('v-datetime-picker', DatetimePicker)

// Snackbar Bus is used to have 1 common vuetify snackbar component
export const SnackBus = new Vue()

new Vue({
    router,
    store,
    vuetify,
    render: (h) => h(App)
}).$mount('#app')