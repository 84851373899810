<template>
  <v-footer color="secondary" class="secondaryText--text" padless>
    <v-col cols="4" class="d-flex justify-start">Version: {{ version }}</v-col>
    <v-col
      cols="4"
      class="d-flex justify-center secondaryText--text align-center"
    >
      <v-icon color="black" class="pr-1" dense>copyright</v-icon>
      {{ year }} Comcast
    </v-col>
  </v-footer>
</template>

<script>
import axios from 'axios'
import {DateTime} from 'luxon'

export default {
  name: 'AppFooter',
  asyncComputed: {
    status: {
      get() {
        return axios.get('/api/v1/status').then((resp) => resp.data)
      },
      default: {}
    }
  },
  computed: {
    year() {
      return DateTime.local().toFormat('yyyy')
    },
    version() {
      return this.status.version
    }
  }
}
</script>
