var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-form',{ref:"form",attrs:{"disabled":!_vm.getCanEdit ||
    !_vm.hasAccountRolesForEvent(_vm.object) ||
    !_vm.hasWritableAccountRoles},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-container',[_c('v-card-text',[_c('schedule-overrides-dialog',{directives:[{name:"show",rawName:"v-show",value:(_vm.getAccountRoles.scheduleOverride && _vm.view == _vm.scheduleview),expression:"getAccountRoles.scheduleOverride && view == scheduleview"}],ref:"scheduleOverridesDialog",attrs:{"obj":_vm.editedScheduleOverride,"mediapointid":_vm.mediapointid,"view":_vm.scheduleview,"opened":_vm.opened,"startorend":_vm.startorend},on:{"scheduleOverridesChange":_vm.changeScheduleOverride,"changeRadio":_vm.changeRadio}})],1),_c('v-card-actions',[_c('v-spacer'),(
          !_vm.getCanEdit ||
          !_vm.hasAccountRolesForEvent(_vm.object) ||
          !_vm.hasWritableAccountRoles
        )?_c('v-btn',{staticClass:"body-1 text--secondary",attrs:{"text":""},nativeOn:{"click":function($event){return _vm.cancel.apply(null, arguments)}}},[_vm._v("Close")]):_vm._e(),(
          _vm.getCanEdit &&
          _vm.hasAccountRolesForEvent(_vm.object) &&
          _vm.hasWritableAccountRoles
        )?_c('v-btn',{staticClass:"body-1 text--secondary",attrs:{"text":""},nativeOn:{"click":function($event){return _vm.cancel.apply(null, arguments)}}},[_vm._v("Cancel")]):_vm._e(),(
          _vm.getCanEdit &&
          _vm.hasAccountRolesForEvent(_vm.object) &&
          _vm.hasWritableAccountRoles
        )?_c('v-btn',{attrs:{"depressed":"","color":"accent","disabled":!_vm.valid || !_vm.getCanEdit || !_vm.hasWritableAccountRoles},nativeOn:{"click":function($event){return _vm.save(_vm.object)}}},[_vm._v("Save")]):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }