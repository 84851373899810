import { SET_EVENTS, SET_SCHEDULE_OVERRIDE, SET_EVENT_OR_SO, CLEAR } from '../../types'

export default {
    [SET_EVENTS](state, data) {
        state.events = data
    },
    [SET_SCHEDULE_OVERRIDE](state, data) {
        state.scheduleoverride = data
    },
    [SET_EVENT_OR_SO](state, data) {
        state.eventorso = data
    },
    [CLEAR](state) {
        state.events = []
    }
}