import axios from 'axios'
import { API_BASE } from '../../types'

const updateEvent = ({ state }, obj) => {
    const events = new Promise((resolve, reject) => {
        const { station, account, token, data } = obj

        if (data.season === '' && data.episode === '' && data.series === false) {
            data.series = true
        }

        const d = {
            events: data
        }

        axios
            .put(API_BASE + state.apiRoute, d, {
                params: {
                    token: token,
                    station: station,
                    account: account
                },
                validateStatus: (status) => status < 400
            })
            .then(() => resolve())
            .catch((error) => {
                reject(error)
            })

        return null
    })

    return events
}

const requestEvents = ({ commit, state }, obj) => {
    const events = new Promise((resolve, reject) => {
        const { station, account, token, from, to } = obj

        if (station.length === 0) {
            return
        }

        axios
            .get(API_BASE + state.apiRoute, {
                params: {
                    token: token,
                    station: station,
                    account: account,
                    from: from,
                    to: to
                },
                validateStatus: (status) => status < 400
            })
            .then((response) => {
                let events = response.data
                if (events === null || events.length === 0) {
                    commit('SET_EVENTS', [])
                    resolve([])
                }

                if (events.events === null) {
                    commit('SET_EVENTS', [])
                    resolve([])
                }

                commit('SET_EVENTS', events.events)
                resolve(events.events)
            })
            .catch((err) => reject(err))
        return null
    })
    return events
}

const requestScheduleOverride = ({ commit }, obj) => {
    const scheduleoverride = new Promise((resolve, reject) => {
        const { station, account, token, mediapointid } = obj

        axios
            .get(API_BASE + 'scheduleoverrides', {
                params: {
                    token,
                    account,
                    station: station
                },
                validateStatus: (status) => status < 400
            })
            .then((response) => {
                let scheduleoverrides = response.data
                if (scheduleoverrides === null || scheduleoverrides.length === 0) {
                    commit('SET_SCHEDULE_OVERRIDE', {})
                    resolve({})
                    return {}
                }

                if (scheduleoverrides.scheduleoverrides === null) {
                    commit('SET_SCHEDULE_OVERRIDE', {})
                    resolve({})
                    return {}
                }

                const schedoverride = scheduleoverrides.scheduleoverrides.filter((schedOvrd) => {
                    return schedOvrd.mediapointid == mediapointid
                })
                commit('SET_SCHEDULE_OVERRIDE', schedoverride[0])
                resolve(schedoverride[0])
            })
            .catch((err) => reject(err))
        return null
    })
    return scheduleoverride
}


const requestEventOrSO = ({ commit }, obj) => {
    const eventorso = new Promise((resolve, reject) => {
        const { station, account, token, startorend, mpid } = obj

        axios
            .get(API_BASE + 'eventorso', {
                params: {
                    token: token,
                    account: account,
                    startorend: startorend,
                    station: station,
                    mpid: mpid
                },
                validateStatus: (status) => status < 400
            })
            .then((response) => {
                let eventorso = response.data
                if (eventorso === null) {
                    commit('SET_EVENT_OR_SO', {})
                    resolve({})
                    return {}
                }

                commit('SET_EVENT_OR_SO', eventorso)
                resolve(eventorso)
            })
            .catch((err) => reject(err))
        return null
    })
    return eventorso
}

export default {
    updateEvent,
    requestEvents,
    requestScheduleOverride,
    requestEventOrSO
}