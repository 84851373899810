import axios from 'axios'
import _ from 'lodash'

const requestAuth = ({ commit, state }, obj) => {
    const dataObj = obj
    if (dataObj.data.username.indexOf('mpx') === -1) {
        const usermpx = `mpx/${dataObj.data.username}`
        dataObj.data.username = usermpx
    }

    const userAuthData = new Promise((resolve, reject) => {
        axios
            .get(state.authURL, {
                timeout: 2000,
                auth: {
                    username: dataObj.data.username,
                    password: dataObj.data.password
                },
                params: {
                    schema: '1.0',
                    form: 'cjson'
                },
                validateStatus: (status) => status < 400
            })
            .then((response) => {
                if (response.data.responseCode > 400) {
                    if (typeof response.data.description !== 'undefined') {
                        reject(response.data.description)
                    } else {
                        reject(response.data)
                    }
                }

                commit('SET_USERID', response.data.signInResponse.userId)
                commit('SET_USERNAME', response.data.signInResponse.userName)
                commit('SET_TOKEN', response.data.signInResponse.token)
                commit('SET_ISAUTHENTICATED', true)

                resolve(response.data)
            })
            .catch((err) => reject(err))
    })
    return userAuthData
}

const requestAccounts = ({ commit, state }, obj) => {
    const userAccountData = new Promise((resolve, reject) => {
        const { token, search } = obj

        let params = {
            token: token,
            schema: '1.0',
            form: 'cjson'
        }

        if (search) {
            params['bytitlePrefix'] = search
        }

        axios
            .get(state.accountURL, {
                timeout: 2000,
                params: params,
                validateStatus: (status) => status < 400
            })
            .then((response) => {
                if (response.data.entries.length > 0) {
                    let accounts = []
                    for (
                        let index = 0; index < response.data.entries.length; index += 1
                    ) {
                        const { title, id, ownerId } = response.data.entries[index]
                        const account = {}
                        const idSplit = _.split(id, '/')
                        account.id = idSplit[idSplit.length - 1]
                        account.name = title
                        account.owner = ownerId
                        accounts.push(account)
                    }

                    // if an account has already been selected we want to add it to the 
                    if (state.accountName) {
                        const a = _.find(accounts, { name: state.accountName })
                        if (typeof a == 'undefined') {
                            accounts.push(state.account)
                        }
                    }

                    commit('SET_ACCOUNTS', accounts)
                }
                resolve(response.data)
            })
            .catch((err) => reject(err))
    })
    return userAccountData
}

const requestAllRoles = ({ commit, state }) => {
    const allRolesData = new Promise((resolve, reject) => {
        axios
            .get('/proxy/v1/affiliate/roles', {
                params: {
                    token: state.token,
                    account: state.accountID
                },
                validateStatus: (status) => status < 400
            })
            .then((response) => {
                const { account, user } = response.data
                commit('SET_ACCOUNT_ROLES', account)
                commit('SET_ROLE_TITLE', user.roleTitle)
                commit('SET_READ_ONLY', false)
                commit('SET_ROLE', user.role)

                resolve(response.data)
            })
            .catch((err) => {
                reject(err)
            })
    })
    return allRolesData
}

const setAccountName = ({ commit }, account) => {
    commit('SET_ACCOUNTNAME', account)
}

const setAccountID = ({ commit }, account) => {
    commit('SET_ACCOUNTID', account)
}

const setAccount = ({ commit }, account) => {
    commit('SET_ACCOUNT', account)
}

const signOut = ({ commit }) => {
    commit('app/CLEAR', null, { root: true })
    commit('event/CLEAR', null, { root: true })
    commit('rule/CLEAR', null, { root: true })
    commit('scheduleoverride/CLEAR', null, { root: true })
    commit('station/CLEAR', null, { root: true })
    commit('task/CLEAR', null, { root: true })
    commit('time/CLEAR', null, { root: true })
    commit('SET_ISAUTHENTICATED', false)
    commit('SET_SIGNOUT')
}

const setUsername = ({ commit }, username) => {
    commit('SET_USERNAME', username)
}

const setAuthenticated = ({ commit }, bool) => {
    commit('SET_ISAUTHENTICATED', bool)
}

const setRole = ({ commit }, role) => {
    commit('SET_ROLE', role)
}

export default {
    requestAuth,
    requestAccounts,
    requestAllRoles,
    setAccountName,
    setAccountID,
    setAccount,
    setUsername,
    setAuthenticated,
    setRole,
    signOut
}