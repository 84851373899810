import {mapGetters, mapActions} from 'vuex'

export default {
  computed: {
    ...mapGetters('auth', [
      'getAccountID',
      'getAccount',
      'getAccountRoles',
      'getUserID',
      'getToken',
      'isAuthenticated'
    ]),
    ...mapGetters('station', ['getStation'])
  },
  methods: {
    ...mapActions('auth', ['requestAllRoles']),
    ...mapActions('app', ['setUIReady']),
    setPermissions() {
      this.requestAllRoles()

      if (
        this.isAuthenticated &&
        this.getAccount !== {} &&
        this.getStation !== '' &&
        this.getAccountRoles !== {}
      ) {
        this.setUIReady(true)
      }
    }
  },
  asyncComputed: {
    allRoles: {
      lazy: true,
      get() {
        return this.requestAllRoles().then((s) => s)
        .catch(() => {
          return {}
        })
      },
      default: {}
    }
  }
}
