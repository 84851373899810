import * as types from '../../types'

export default {
  [types.SET_UI_READY](state, data) {
    state.uiReady = data
  },
  [types.CLEAR](state) {
    state.uiReady = false
  }
}
