import axios from 'axios'
import { API_BASE } from '../../types'
import _ from 'lodash'

const setStation = ({ commit }, station) => {
    commit('SET_TASKS_STATION', [station])
    commit('SET_RULES_STATION', [station])
    commit('SET_SCHEDULE_STATION', station)
    commit('SET_SCHEDULE_OVERRIDES_STATION', [station])
    commit('SET_SETTINGS_STATION', station)
    commit('SET_NOTIFICATIONS_STATION', [station])
    commit('SET_STATION', station)
}

const setStations = ({ commit }, stations) => {
    commit('SET_STATIONS', _.compact(stations))
}

const setTasksStation = ({ commit, state }, s) => {
    const stations = _.compact(s)
    commit('SET_TASKS_STATION', stations)
    if (state.stationsEmptyState) {
        commit('SET_ALL_STATIONS', stations)
        commit('SET_STATIONS_STATE_EMPTY', false)
    }
}

const setRulesStation = ({ commit, state }, s) => {
    const stations = _.compact(s)
    commit('SET_RULES_STATION', stations)
    if (state.stationsEmptyState) {
        commit('SET_ALL_STATIONS', stations)
        commit('SET_STATIONS_STATE_EMPTY', false)
    }
}

const setScheduleStation = ({ commit, state }, s) => {
    commit('SET_SCHEDULE_STATION', s)
    if (state.stationsEmptyState) {
        commit('SET_ALL_STATIONS', s)
        commit('SET_STATIONS_STATE_EMPTY', false)
    }
}

const setScheduleOverridesStation = ({ commit, state }, s) => {
    commit('SET_SCHEDULE_OVERRIDES_STATION', s)
    if (state.stationsEmptyState) {
        commit('SET_ALL_STATIONS', s)
        commit('SET_STATIONS_STATE_EMPTY', false)
    }
}

const setSettingsStation = ({ commit, state }, s) => {
    commit('SET_SETTINGS_STATION', s)
    if (state.stationsEmptyState) {
        commit('SET_ALL_STATIONS', s)
        commit('SET_STATIONS_STATE_EMPTY', false)
    }
}

const setNotificationsStation = ({ commit, state }, s) => {
    const stations = _.compact(s)
    commit('SET_NOTIFICATIONS_STATION', stations)
    if (state.stationsEmptyState) {
        commit('SET_ALL_STATIONS', stations)
        commit('SET_STATIONS_STATE_EMPTY', false)
    }
}

const stationClear = ({ commit }) => {
    commit('CLEAR')
}

const setStationsStateEmpty = ({ commit }) => {
    commit('SET_STATIONS_STATE_EMPTY', true)
}

const requestStations = ({ commit, state, rootState }) => {
    const stationData = new Promise((resolve, reject) => {
        const { accountID, token } = rootState.auth

        if (accountID === '') {
            reject('account is empty')
        }

        if (token === '') {
            reject('token is empty')
        }

        axios
            .get(API_BASE + state.apiRoute, {
                params: {
                    token: token,
                    account: accountID
                },
                validateStatus: (status) => status < 400
            })
            .then((response) => {
                if (response.data.responseCode > 400) {
                    if (typeof response.data.stations !== 'undefined') {
                        reject(response.data)
                    } else {
                        reject(response.data)
                    }
                }
                commit('SET_STATIONS', response.data.stations)
                resolve(response.data.stations)
            })
            .catch((err) => reject(err))
    })
    return stationData
}

export default {
    setStation,
    setStations,
    setTasksStation,
    setRulesStation,
    setScheduleStation,
    setScheduleOverridesStation,
    setSettingsStation,
    setNotificationsStation,
    requestStations,
    stationClear,
    setStationsStateEmpty
}