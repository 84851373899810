<template>
  <v-autocomplete
    v-model="selected"
    :value="station"
    :items="view == scheduleoverridesview || view == scheduleview ? getStations : getStationNames"
    :rules="stationRules"
    label="Stations"
    @change="$emit('station-change', selected)"
    :multiple="multiple"
    :dense="dense"
    required
  >
    <template #selection="{item, index}" v-if="multiple">
      <span v-if="index === 0 && (view == scheduleoverridesview || view == scheduleview)">{{ item.value }}</span>
      <span v-else-if="index === 0">{{ item }}</span>
      <span v-if="index === 1" class="secondaryText--text caption ml-1">
        (+{{ selected.length - 1 }} others)</span
      >
    </template>
    <template #prepend-item v-if="multiple">
      <v-list-item ripple @click.native="toggle">
        <v-list-item-action>
          <v-icon :color="selected.length > 0 ? 'primary' : ''">{{
            icon
          }}</v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>Select All</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-divider class="mt-2"></v-divider>
    </template>
  </v-autocomplete>
</template>

<script>
import stationsMixin from '../../mixins/stations'

export default {
  name: 'Stations',
  mixins: [stationsMixin],
  props: {
    station: null,
    multiple: {
      type: Boolean,
      default: () => false
    },
    dense: {
      type: Boolean,
      default: () => false
    },
    view: {
      type: String,
      default: () => ''
    },
    currentStation: {
      type: String,
      default: () => ''
    }
  },
  data: () => ({
    selected: [],
    noStations: 'No stations available',
    stationRules: [(v) => (v && v.length > 0) || 'Station is required'],
    scheduleview: 'Schedule',
    scheduleoverridesview: 'Schedule Overrides'
  }),
  computed: {
    selectedAllStations() {
      return this.station.length === this.stations.length
    },
    selectedSomeStations() {
      return this.station.length > 0 && !this.selectedAllStations
    },
    icon() {
      if (this.selectedAllStations) return 'mdi-checkbox-marked'
      if (this.selectedSomeStations) return 'mdi-minus-box'
      return 'mdi-checkbox-blank-outline'
    },
    getStations(){
      const stations = this.getStationNames.map((stationName) => {
        return {
          value: stationName,
          text: stationName,
          disabled: this.currentStation == stationName
        }
      })
      return stations
    }
  },
  methods: {
    toggle() {
      this.$nextTick(() => {
        if (this.selectedAllStations) {
          if (this.view == this.scheduleoverridesview || this.view == this.scheduleview){
            this.selected = [this.currentStation]
          } else {
            this.selected = []
          }
          this.$emit('station-change', this.selected)
        } else {
          this.selected = [...this.getStationNames]
          this.$emit('station-change', this.selected)
        }
      })
    }
  },
  watch: {
    station(val) {
      this.selected = val
    }
  },
  mounted() {
    this.selected = this.station
  }
}
</script>
