<template>
  <v-form
    :disabled="
      !getCanEdit ||
      !hasAccountRolesForEvent(object) ||
      !hasWritableAccountRoles
    "
    v-model="valid"
    ref="form"
  >
    <v-container>
      <v-card-text>
        <section>
          <v-row
            align="end"
            justify="start"
            v-if="
              (!hasWritableAccountRoles || !hasAccountRolesForEvent(object)) &&
              !options.multiple
            "
          >
            <v-col>
              <p class="body-1 text--secondary">
                This event cannot be modified.
              </p>
            </v-col>
          </v-row>
          <v-row align="end" justify="start" v-if="!getCanEdit">
            <v-col>
              <p class="body-1 text--secondary">
                This event is read-only for user.
              </p>
            </v-col>
          </v-row>
          <v-row
            align="end"
            justify="start"
            v-if="showApplication && hasWritableAccountRoles"
          >
            <v-col>
              <v-select
                v-model="object.application"
                :value="object.application"
                :items="applications"
                label="Apply to"
                :rules="applicationRules"
                required
              ></v-select>
            </v-col>
          </v-row>
          <div class="d-flex justify-space-between">
            <v-card
              tile
              flat
              style="width: 80px"
              v-show="
                getAccountRoles.authKill || getAccountRoles.authKillReadOnly
              "
            >
              <p class="body-1 text--secondary">AuthKill</p>
              <v-switch
                class="pt-1"
                justify="end"
                :disabled="
                  getAccountRoles.authKillReadOnly && !getAccountRoles.authKill
                "
                v-model="object.authkill"
                :value="object.authkill"
                :label="object.authkill | isEnabled"
                :false-value="false"
                :true-value="true"
              ></v-switch>
            </v-card>
            <v-card
              tile
              flat
              style="width: 80px"
              v-show="
                (object.uhdavailable && getAccountRoles.uhd) ||
                (options.multiple && getAccountRoles.uhd)
              "
            >
              <v-tooltip top>
                <template v-slot:activator="{on, attrs}">
                  <p v-bind="attrs" v-on="on" class="body-1 text--secondary">
                    UHD
                  </p>
                </template>
                <span>Ultra High Definition</span>
              </v-tooltip>
              <v-switch
                class="pt-1"
                justify="end"
                v-model="object.takeuhd"
                :value="object.takeuhd"
                :label="object.takeuhd | isEnabled"
                :false-value="false"
                :true-value="true"
              ></v-switch>
            </v-card>
            <v-card
              tile
              flat
              style="width: 80px"
              v-show="getAccountRoles.embargo"
            >
              <p class="body-1 text--secondary">Embargo</p>
              <v-switch
                class="pt-1"
                justify="end"
                v-model="object.embargo"
                :value="object.embargo"
                :label="object.embargo | isEnabled"
                :false-value="false"
                :true-value="true"
              ></v-switch>
            </v-card>
            <v-card
              tile
              flat
              style="width: 80px"
              v-show="getAccountRoles.startOver"
            >
              <p class="body-1 text--secondary">Startover</p>
              <v-switch
                class="pt-1"
                v-model="object.startover"
                :value="object.startover"
                :label="object.startover | isEnabled"
                :false-value="false"
                :true-value="true"
              ></v-switch>
            </v-card>
            <v-card
              tile
              flat
              style="width: 80px"
              v-show="getAccountRoles.lookBack"
            >
              <p class="body-1 text--secondary">Lookback</p>
              <v-switch
                class="pt-1"
                v-model="object.lookback"
                :value="object.lookback"
                :label="object.lookback | isEnabled"
                :false-value="false"
                :true-value="true"
              ></v-switch>
            </v-card>
          </div>
        </section>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          class="body-1 text--secondary"
          text
          v-if="
            !getCanEdit ||
            !hasAccountRolesForEvent(object) ||
            !hasWritableAccountRoles
          "
          @click.native="cancel"
          >Close</v-btn
        >
        <v-btn
          class="body-1 text--secondary"
          text
          v-if="
            getCanEdit &&
            hasAccountRolesForEvent(object) &&
            hasWritableAccountRoles
          "
          @click.native="cancel"
          >Cancel</v-btn
        >
        <v-btn
          class="secondaryText--text"
          color="secondary"
          v-if="
            getCanEdit &&
            showDefaultsButton &&
            hasAccountRolesForEvent(object) &&
            hasWritableAccountRoles
          "
          @click.native="applyDefaults()"
          >fill defaults</v-btn
        >
        <v-btn
          depressed
          color="accent"
          v-if="
            getCanEdit &&
            hasAccountRolesForEvent(object) &&
            hasWritableAccountRoles
          "
          :disabled="!valid || !getCanEdit || !hasWritableAccountRoles"
          @click.native="save"
          >Save</v-btn
        >
      </v-card-actions>
    </v-container>
  </v-form>
</template>

<script>

import {mapGetters} from 'vuex'

export default {
  name: 'Events',
  data: () => ({
    object: {},
    options: {
      multiple: false
    },
    resolve: null,
    reject: null,
    valid: false,
    default: {},
    showDefaultsButton: false,
    applicationRules: [(v) => !!v || 'Selection required'],
    applications: [
      {
        text: 'Series',
        value: 'series'
      },
      {
        text: 'Season',
        value: 'season'
      },
      {
        text: 'Episode',
        value: 'episode'
      }
    ],
    empty: {
      station: '',
      application: '',
      showtitle: '',
      series: false,
      season: '',
      episode: '',
      embargo: false,
      startover: false,
      lookback: false,
      authkill: false,
      takeuhd: false,
      uhdavailable: false,
      type: ''
    }
  }),
  computed: {
    ...mapGetters('auth', [
      'getCanEdit',
      'getAccountID',
      'getToken',
      'getAccountRoles',
      'hasAccountRolesForEvent',
      'hasWritableAccountRoles'
    ]),
    showApplication() {
      if (this.object.season === '' && this.object.episode === '') {
        return false
      }
      return true
    }
  },
  methods: {
    open(event, options) {
      this.$refs.form.resetValidation()
      this.object = event
      this.default = Object.assign(this.empty, event)
      this.options = Object.assign(this.options, options)
      this.showDefaultsButton = false

      if (!event.rights) {
        this.setNoRights(this.object)
        if (!this.options.multiple) {
          this.showDefaultsButton = true
          if (!this.getAccountRoles.network && event.type == 'network') {
            this.showDefaultsButton = false
          }
        }
      }

      return new Promise((resolve, reject) => {
        this.resolve = resolve
        this.reject = reject
      })
    },
    save() {
      if (this.$refs.form.validate() && this.getCanEdit) {
        this.close(this.object)
      }
    },
    cancel() {
      this.close({})
    },
    close(obj) {
      this.object = this.empty
      this.default = this.empty

      if (this.resolve != null) {
        this.resolve(obj)
      }
    },
    applyDefaults() {
      this.object.application = this.default.application

      if (this.getAccountRoles.embargo) {
        this.object.embargo = this.default.embargo
      }

      if (this.getAccountRoles.startOver) {
        this.object.startover = this.default.startover
      }

      if (this.getAccountRoles.lookBack) {
        this.object.lookback = this.default.lookback
      }

      if (this.getAccountRoles.uhd && this.default.uhdavailable) {
        this.object.uhdavailable = this.default.uhdavailable
        this.object.takeuhd = this.default.takeuhd
      }

      if (this.getAccountRoles.authKill) {
        this.object.authkill = this.default.authkill
      }
    },
    setNoRights(event) {
      event.application = ''
      event.embargo = false
      event.startover = false
      event.lookback = false
      event.takeuhd = false
      event.authkill = false
    }
  }
}
</script>