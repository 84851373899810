import Vue from 'vue'
import Vuex from 'vuex'
import appModule from './modules/app/index'
import eventsModule from './modules/events/index'
import tasksModule from './modules/tasks/index'
import rulesModule from './modules/rules/index'
import stationModule from './modules/station/index'
import scheduleOverridesModule from './modules/scheduleoverrides/index'
import authModule from './modules/auth/index'
import timeModule from './modules/time/index'
import emailsModule from './modules/emails/index'
import notificationsModule from './modules/notifications/index'
import createPersistedState from 'vuex-persistedstate'

Vue.use(Vuex)

const modules = {
    rule: rulesModule,
    event: eventsModule,
    task: tasksModule,
    app: appModule,
    station: stationModule,
    scheduleoverride: scheduleOverridesModule,
    auth: authModule,
    time: timeModule,
    email: emailsModule,
    notification: notificationsModule
}

export default new Vuex.Store({
    strict: process.env.NODE_ENV !== 'production',
    // session storage is used by each browser tab.
    // This way we can use the same user on two different tabs with two different accounts.
    plugins: [
        createPersistedState({
            key: 'LRM',
            storage: window.sessionStorage
        })
    ],
    modules
})