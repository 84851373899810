import {SET_NOTIFICATIONS, CLEAR} from '../../types'

export default {
  [SET_NOTIFICATIONS](state, data) {
    state.notifications = data
  },
  [CLEAR](state) {
    state.notifications = []
  }
}
