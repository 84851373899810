import _ from 'lodash'

const getStation = (state) => state.station
const getStations = (state) => state.stations
const getStationsEmptyState = (state) => state.stationsEmptyState
const getStationNames = (state) => _.map(state.stations, 'name')
const getTasksStation = (state) => state.tasksStation
const getRulesStation = (state) => state.rulesStation
const getScheduleStation = (state) => state.scheduleStation
const getScheduleOverridesStation = (state) => state.scheduleOverridesStation
const getSettingsStation = (state) => state.settingsStation
const getNotificationsStation = (state) => state.notificationsStation

export default {
    getStation,
    getStations,
    getStationsEmptyState,
    getStationNames,
    getTasksStation,
    getRulesStation,
    getScheduleStation,
    getScheduleOverridesStation,
    getSettingsStation,
    getNotificationsStation
}