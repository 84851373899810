var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',[(_vm.isUIReady)?_c('v-row',{staticClass:"secondary pt-1"},[_c('v-col',{staticClass:"pb-0"},[_c('account-selector',{attrs:{"dense":""},on:{"change":_vm.setPermissions}})],1),_c('v-col',{staticClass:"pb-0"},[_c('stations',{attrs:{"dense":"","multiple":"","station":_vm.getRulesStation},on:{"station-change":function($event){_vm.loading = true
          _vm.setRulesStation($event)}}})],1)],1):_vm._e(),_c('v-card',{staticClass:"mt-3",attrs:{"flat":""}},[_c('v-card-title',{staticClass:"pr-0 pl-0 pb-0"},[_c('v-row',{attrs:{"flat":"","dense":""}},[_c('v-col',{staticClass:"d-flex align-end"},[_c('h1',{staticClass:"display-1 primary--text font-weight-bold"},[_vm._v(" Rules "),(!_vm.getCanEdit)?_c('span',{staticClass:"caption text-right primary--text font-weight-bold"},[_vm._v(" (Read-Only)")]):_vm._e()])]),_c('v-spacer'),_c('v-col',{staticClass:"pl-4",attrs:{"cols":"12","lg":"6"}},[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":"","clearable":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1)],1),_c('v-data-table',{attrs:{"show-select":_vm.getCanEdit,"item-key":"id","headers":_vm.headersAvailable,"items":_vm.rules,"no-results-text":"No matching rules found.","no-data-text":"No rules found. Maybe a station needs to be selected","loading-text":"Loading rules...","search":_vm.search,"loading":_vm.loading},on:{"click:row":_vm.editRule},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-row',{staticClass:"pb-1 mt-1",attrs:{"flat":""}},[_c('v-col',{staticClass:"d-flex justify-end align-end"},[(_vm.getCanEdit)?_c('confirm',{ref:"confirm"}):_vm._e(),(_vm.selected.length && _vm.getCanEdit)?_c('v-btn',{staticClass:"mr-2",attrs:{"color":"error","medium":"","elevation":"0","dark":""},nativeOn:{"click":function($event){return _vm.deleteSelected.apply(null, arguments)}}},[_vm._v("Delete")]):_vm._e(),_c('v-dialog',{attrs:{"max-width":"600px"},on:{"click:outside":_vm.close},scopedSlots:_vm._u([(_vm.getCanEdit)?{key:"activator",fn:function(ref){
          var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"color":"accent","medium":"","outlined":"","dark":""}},on),[_vm._v("New Rule")])]}}:null],null,true),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-toolbar',{staticClass:"primaryText--text",attrs:{"fixed":"","color":"primary"}},[_c('v-toolbar-title',[_vm._v(_vm._s(_vm.formTitle))]),_c('v-spacer'),_c('v-btn',{attrs:{"icon":"","dark":""},nativeOn:{"click":function($event){return _vm.close.apply(null, arguments)}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),_c('v-form',{ref:"form",attrs:{"disabled":!_vm.getCanEdit},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-card-text',[_c('rules-dialog',{attrs:{"obj":_vm.editedRule},on:{"rulesChange":_vm.changeRule}})],1)],1),_c('v-card-actions',[_c('v-spacer'),(!_vm.getCanEdit)?_c('v-btn',{staticClass:"body-1 text--secondary",attrs:{"text":""},nativeOn:{"click":function($event){return _vm.close.apply(null, arguments)}}},[_vm._v("Close")]):_vm._e(),(_vm.getCanEdit)?_c('v-btn',{staticClass:"body-1 text--secondary",attrs:{"text":""},nativeOn:{"click":function($event){return _vm.close.apply(null, arguments)}}},[_vm._v("Cancel")]):_vm._e(),(_vm.getCanEdit)?_c('v-btn',{attrs:{"depressed":"","color":"accent","disabled":!_vm.valid || !_vm.getCanEdit},nativeOn:{"click":function($event){return _vm.save.apply(null, arguments)}}},[_vm._v("Save")]):_vm._e()],1)],1)],1)],1)],1),_c('v-divider')]},proxy:true},{key:"item.series",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("isEnabled")(item.series))+" ")]}},{key:"item.season",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("fillEmpty")(item.season))+" ")]}},{key:"item.episode",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("fillEmpty")(item.episode))+" ")]}},{key:"item.authkill",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("isEnabled")(item.authkill))+" ")]}},{key:"item.takeuhd",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("isEnabled")(item.takeuhd))+" ")]}},{key:"item.embargo",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("isEnabled")(item.embargo))+" ")]}},{key:"item.startover",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("isEnabled")(item.startover))+" ")]}},{key:"item.lookback",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("isEnabled")(item.lookback))+" ")]}}],null,true),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }