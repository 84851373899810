<template>
  <v-snackbar :color="color" :timeout="timeout" top v-model="visible">
    <v-icon :color="iconcolor" v-if="icon">{{ icon }}</v-icon>
    <span class="ml-2 body-1" :class="textclass">{{ text }}</span>

    <template v-slot:action>
      <v-btn color="white" text @click="visible = false"> Close </v-btn>
    </template>
  </v-snackbar>
</template>

<script>
import {SnackBus} from '../../main'

export default {
  name: 'AppSnack',
  data() {
    return {
      timeout: 3000,
      visible: false,
      icon: 'close',
      text: '',
      color: 'primary',
      iconcolor: 'white',
      textclass: ''
    }
  },
  created() {
    SnackBus.$on(
      'open',
      ({
        text = '',
        color = 'primary',
        icon = '',
        textclass = 'white--text',
        iconcolor = 'white'
      }) => {
        this.text = text
        this.color = color
        this.icon = icon
        this.visible = true
        this.textclass = textclass
        this.iconcolor = iconcolor
      }
    )
    SnackBus.$on('close', () => {
      this.visible = false
    })
    SnackBus.$on('error', (text) => {
      this.text = text
      this.color = 'red'
      this.icon = 'error'
      this.visible = true
      this.textclass = 'white--text'
      this.iconcolor = 'white'
    })
    document.addEventListener('keyup', this.handleKeyup)
  },
  beforeDestroy() {
    document.removeEventListener('keyup', this.handleKeyup)
  },
  methods: {
    handleClose() {
      this.visible = false
    },
    handleKeyup(e) {
      if (e.keyCode === 27) this.handleClose()
    }
  }
}
</script>
