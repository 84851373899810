<template>
  <section>
    <v-row v-if="isUIReady" class="secondary pt-1">
      <v-col class="pb-0">
        <account-selector dense @change="setPermissions"></account-selector>
      </v-col>
      <v-col class="pb-0">
        <stations
          dense
          multiple
          :station="getScheduleOverridesStation"
          @station-change="
            loading = true
            setScheduleOverridesStation($event)
          "
        ></stations>
      </v-col>
    </v-row>
    <v-card flat class="mt-3">
      <v-card-title class="pr-0 pl-0 pb-0">
        <v-row flat dense>
          <v-col class="d-flex align-end">
            <h1 class="display-1 primary--text font-weight-bold">
              Schedule Overrides
              <span
                class="caption text-right primary--text font-weight-bold"
                v-if="!getCanEdit"
              >
                (Read-Only)</span
              >
            </h1>
          </v-col>

          <v-spacer></v-spacer>
          <v-col cols="12" lg="6" class="pl-4">
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Search"
              single-line
              clearable
              hide-details
            ></v-text-field>
          </v-col>
        </v-row>
      </v-card-title>
      <v-data-table
        v-model="selected"
        :show-select="getCanEdit"
        item-key="id"
        :headers="headersAvailable"
        :items="scheduleoverrides"
        no-results-text="No matching schedule overrides found."
        no-data-text="No schedule overrides found. Maybe a station needs to be selected"
        loading-text="Loading schedule overrides..."
        :search="search"
        @click:row="editScheduleOverride"
        :loading="loading"
      >
        <template #top>
          <v-row class="pb-1 mt-1" flat>
            <v-col class="d-flex justify-end align-end">
              <confirm v-if="getCanEdit" ref="confirm"></confirm>
              <v-btn
                color="error"
                medium
                elevation="0"
                class="mr-2"
                dark
                v-if="selected.length && getCanEdit"
                @click.native="deleteSelected"
                >Delete
                </v-btn>
              <v-dialog
                v-model="dialog"
                @click:outside="close"
                max-width="600px"
              >
                <template #activator="{on}" v-if="getCanEdit">
                  <v-btn color="accent" medium outlined dark v-on="on"
                    >New Schedule Override</v-btn
                  >
                </template>
                <v-card>
                  <v-toolbar fixed color="primary" class="primaryText--text">
                    <v-toolbar-title>{{ formTitle }}</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-btn icon dark @click.native="close">
                      <v-icon>mdi-close</v-icon>
                    </v-btn>
                  </v-toolbar>
                  <v-form :disabled="!getCanEdit" ref="form" v-model="valid">
                    <v-card-text>
                      <schedule-overrides-dialog ref="scheduleOverridesModal"
                        :obj="editedScheduleOverride"
                        :editedIndex="editedIndex"
                        @scheduleOverridesChange="changeScheduleOverride"
                        :startorend="editedScheduleOverride.startorend"
                      ></schedule-overrides-dialog>
                    </v-card-text>
                  </v-form>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                      class="body-1 text--secondary"
                      text
                      v-if="!getCanEdit"
                      @click.native="close"
                      >Close</v-btn
                    >
                    <v-btn
                      class="body-1 text--secondary"
                      text
                      v-if="getCanEdit"
                      @click.native="close"
                      >Cancel</v-btn
                    >
                    <v-btn
                      depressed
                      color="accent"
                      v-if="getCanEdit"
                      :disabled="!valid || error || !getCanEdit"
                      @click.native="save"
                      >Save</v-btn
                    >
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-col>
          </v-row>
          <v-divider></v-divider>
        </template>
        <template #[`item.effectiveness`]="{item}">
          {{ item.effectiveness | userTime }}
        </template>
        <template #[`item.expires`]="{item}">
          {{ item.expires | userTime }}
        </template>
        <template  #[`item.matchtime`]="{item}" >
          <template v-if="item.matchtime != '0001-01-01T00:00:00Z'">
            {{item.matchtime | userTime}}
          </template>
        </template>
        <template #[`item.start`]="{item}">
          {{ item.start | userTime }}
        </template>
        <template #[`item.end`]="{item}">
          {{ item.end | userTime }}
        </template>
      </v-data-table>
    </v-card>
  </section>
</template>

<script>
import {mapGetters, mapActions} from 'vuex'
import ScheduleOverridesDialog from '../components/form/ScheduleOverrides'
import Confirm from '../components/dialog/Confirm'
import AccountSelector from '../components/auth/AccountSelector'
import Stations from '../components/form/Stations'
import permissionsMixin from '../mixins/permissions'
import _ from 'lodash'
import {SnackBus} from '../main'

const DEFAULT_DATE_TIME = '0001-01-01T00:00:00Z'

export default {
  name: 'ScheduleOverrides',
  mixins: [permissionsMixin],
  data: () => ({
    search: '',
    valid: true,
    loading: false,
    dialog: false,
    selected: [],
    syncInterval: null,
    editedIndex: -1,
    editedScheduleOverride: {
      stations: [],
      id: '',
      mediapointid: '',
      startorend: '',
      effectiveness: '',
      expires: '',
      matchtime: '',
      start: '',
      end: ''
    },
    scheduleOverrideOnOpen: {
      stations: [],
      id: '',
      mediapointid: '',
      startorend: '',
      effectiveness: '',
      expires: '',
      matchtime: '',
      start: '',
      end: ''
    },
    emptyScheduleOverride: {
      stations: [],
      id: '',
      mediapointid: '',
      startorend: '',
      effectiveness: '',
      expires: '',
      matchtime: '',
      start: '',
      end: ''
    },
    headers: [
      // Rules engine get returns station as single string and create/update from AM sends stations in array
      // hence binding the field name as station
      {text: 'Station', value: 'station'},
      {text: 'Mediapoint ID', value: 'mediapointid'},
      {text: 'Start/End', value: 'startorend'},
      {text: 'Effectiveness', value: 'effectiveness'},
      {text: 'Expires', value: 'expires'},
      {text: 'MatchTime', value: 'matchtime'},
      {text: 'Start', value: 'start'},
      {text: 'End', value: 'end'},
    ],
    error: false
  }),
  computed: {
    ...mapGetters('auth', [
      'getCanEdit',
      'getAccountID',
      'getToken',
      'getRole',
      'getAccountRoles'
    ]),
    ...mapGetters('app', ['isUIReady']),
    ...mapGetters('station', [
      'getStationNames',
      'getScheduleOverridesStation',
      'getStation'
    ]),
    ...mapGetters('scheduleoverride', ['getScheduleOverrides']),
    formTitle() {
      return this.editedIndex === -1 ? 'New Item' : 'Edit Item'
    },
    headersAvailable() {
      // This will update the headers based on the feature roles enabled for this account.
      var removeKeys = _.keys(_.pickBy(this.getAccountRoles, (v) => !v))
      return _.reduce(
        this.headers,
        function (result, value) {
          if (!_.includes(removeKeys, value.apiKey)) {
            result.push(value)
          }
          return result
        },
        []
      )
    }
  },
  asyncComputed: {
    scheduleoverrides: {
      lazy: true,
      get() {
        return this.requestScheduleOverrides({
          station: this.getScheduleOverridesStation,
          account: this.getAccountID,
          token: this.getToken
        })
          .then((scheduleoverrides) => {
            if (scheduleoverrides.length === 0) return []
            return scheduleoverrides
          })
          .catch(() => {
            if (this.getScheduleOverridesStation.length == 0){
              SnackBus.$emit('error', 'Atleast 1 station needs to be selected.')
            } else {
              SnackBus.$emit('error', 'Error getting schedule overrides')
            }
            return []
          })
      },
      default: []
    },   
  },
  methods: {
    ...mapActions('scheduleoverride', [
      'requestScheduleOverrides',
      'updateScheduleOverride',
      'createScheduleOverride',
      'deleteScheduleOverrides'
    ]),
    ...mapActions('station', ['setScheduleOverridesStation']),   
    isFormChanged: function(){
      if (this.scheduleOverrideOnOpen.matchtime !== this.editedScheduleOverride.matchtime) {
        return true
      }
      if (this.scheduleOverrideOnOpen.effectiveness !== this.editedScheduleOverride.effectiveness) {
        return true
      }
      if (this.scheduleOverrideOnOpen.expires !== this.editedScheduleOverride.expires) {
        return true
      }
      if (this.scheduleOverrideOnOpen.start !== this.editedScheduleOverride.start) {
        return true
      }
      if (this.scheduleOverrideOnOpen.end !== this.editedScheduleOverride.end) {
        return true
      }
      if (this.scheduleOverrideOnOpen.startorend !== this.editedScheduleOverride.startorend && this.editedScheduleOverride.startorend) {
        return true
      }
      if (!_.isEqual(this.scheduleOverrideOnOpen.stations, this.editedScheduleOverride.stations)){
        return true
      }
      return false
    },
    sync() {
      this.$asyncComputed.scheduleoverrides.update()
    },
    save() {
      if (!this.isFormChanged()) {
        SnackBus.$emit('error', 'No changes to create/update.' )
        return
      }
      if (this.$refs.form.validate() && this.getCanEdit) {
        let scheduleoverride = _.cloneDeep(this.editedScheduleOverride)

        // if multiple stations are selected the user
        // is wanting this schedule override to apply to multiple stations
        // so the id is no long used.
        if (scheduleoverride?.stations?.length > 1) {
          scheduleoverride.id = ''
        }

        if (scheduleoverride?.matchtime == '') {
          scheduleoverride.matchtime = DEFAULT_DATE_TIME
        }
        if (scheduleoverride?.start == '') {
          scheduleoverride.start = DEFAULT_DATE_TIME
        }
        if (scheduleoverride?.end == '') {
          scheduleoverride.end = DEFAULT_DATE_TIME
        }
        if (scheduleoverride?.expires == '') {
          scheduleoverride.expires = DEFAULT_DATE_TIME
        }
        if (scheduleoverride?.effectiveness == '') {
          scheduleoverride.effectiveness = DEFAULT_DATE_TIME
        }

        this.loading = true

        // Update
        if (this.editedIndex > -1) {
        scheduleoverride.station = this.scheduleOverrideOnOpen.station
          this.updateScheduleOverride({
            station: scheduleoverride.stations,
            account: this.getAccountID,
            token: this.getToken,
            data: [scheduleoverride]
          })
            .then(() => {
              if (scheduleoverride.stations.length < 2){
                SnackBus.$emit('open', {
                  text: `Successfully updated schedule override for the mediapoint ${scheduleoverride.mediapointid} in station ${scheduleoverride?.stations?.[0]}`,
                  icon: 'check',
                  color: 'green'
                })
              } else {
                SnackBus.$emit('open', {
                  text: `Successfully updated schedule override for the mediapoint ${scheduleoverride.mediapointid} in station ${scheduleoverride?.stations?.[0]}. Updating overrides for other stations...`,
                  icon: 'check',
                  color: 'green'
                })
              }              
              this.$asyncComputed.scheduleoverrides.update()
              this.loading = false
            })
            .catch(() => {
              SnackBus.$emit('error', 'Error updating schedule override')
              this.$asyncComputed.scheduleoverrides.update()
              this.loading = false
            })
        } else {
          scheduleoverride.station = scheduleoverride?.stations?.[0]
          // Create
          this.createScheduleOverride({
            station: scheduleoverride.stations,
            account: this.getAccountID,
            token: this.getToken,
            data: [scheduleoverride]
          })
            .then(() => {
              if(scheduleoverride?.stations.length < 2){
                SnackBus.$emit('open', {
                  text: `Successfully created schedule override for the mediapoint ${scheduleoverride.mediapointid} in station ${scheduleoverride.station}`,
                  icon: 'check',
                  color: 'green'
                })
              } else {
                SnackBus.$emit('open', {
                  text: `Successfully created schedule override for the mediapoint ${scheduleoverride.mediapointid} in station ${scheduleoverride.station}. Creating overrides for other stations...`,
                  icon: 'check',
                  color: 'green'
                })
              }
              this.$asyncComputed.scheduleoverrides.update()
              this.loading = false
            })
            .catch((e) => {
              SnackBus.$emit('error', 'Error creating schedule override', e)
              this.$asyncComputed.scheduleoverrides.update()
              this.loading = false
            })
        }
        this.close()
        return
      }
    },
    clearFormValues(){
      this.$refs.scheduleOverridesModal?.clearFormValues()
    },
    close() {
      this.editedScheduleOverride = Object.assign({}, this.emptyScheduleOverride)
      this.scheduleOverrideOnOpen = Object.assign({}, this.emptyScheduleOverride)
      this.dialog = false
      this.clearFormValues()
    },
    editScheduleOverride(item) {
      this.editedIndex = _.findIndex(this.getScheduleOverrides, item)
      this.editedScheduleOverride = Object.assign({}, item)

      // Rules engine GET returns station as single string but create/update from AM sends stations in array
      // hence, sending station value as initial value for the edit model.
      this.editedScheduleOverride.stations = [item.station]
      this.scheduleOverrideOnOpen  = _.cloneDeep(this.editedScheduleOverride)
      setTimeout(() => (this.dialog = true), 10)
      setTimeout(() => this.$refs.form.resetValidation(), 10)
    },
    async deleteSelected() {
      let mpWithStations = this.selected.map((sel) => `${sel.mediapointid} - ${sel.station}`)
      if (
        await this.$refs.confirm.open(
          'Delete schedule overrides',
          mpWithStations,
          `You will delete ${this.selected.length} overrides for the following media point(s) in station(s):`
        )
      ) {
        this.loading = true
        this.deleteScheduleOverrides({
          account: this.getAccountID,
          token: this.getToken,
          data: this.selected
        })
          .then(() => {
            if (this.selected.length < 2) {
              SnackBus.$emit('open', {
                text: `Successfully deleted schedule override for the mediapoint ${this.selected?.[0]?.mediapointid} in station ${this.selected?.[0]?.station}`,
                icon: 'check',
                color: 'green'
              })
            } else {
              SnackBus.$emit('open', {
                text: `Successfully deleted schedule override for the mediapoint ${this.selected?.[0]?.mediapointid} in station ${this.selected?.[0]?.station}. Deleting other overrides...`,
                icon: 'check',
                color: 'green'
              })
            }
            this.selected = []
            this.$asyncComputed.scheduleoverrides.update()
            this.loading = false
          })
          .catch(() => {
            this.selected = []
            SnackBus.$emit('error', 'Error deleting schedule override')
            this.$asyncComputed.scheduleoverrides.update()
            this.loading = false
          })
        this.close()
      }
    },
    changeScheduleOverride(event) {
      if (!this.dialog) {
        return
      }
      this.editedScheduleOverride = event
      if (this.editedScheduleOverride.matchtime == '' && 
          this.editedScheduleOverride.start == '' &&
          this.editedScheduleOverride.end == '' &&
          this.editedScheduleOverride.effectiveness == '' &&
          this.editedScheduleOverride.expires == '') {
            this.error = true
        } else {
            this.error = false
        }        
      setTimeout(() => this.$refs.form.validate(), 20)  
    }
  },
  watch: {
    dialog(val) {
      if (val) {
        setTimeout(() => this.$refs.form.resetValidation(), 10)
        return val
      }
      this.editedIndex = -1
      this.selected = []
      this.editedScheduleOverride = Object.assign(this.editedScheduleOverride, this.emptyScheduleOverride)
      return val
    },
    search() {
      this.selected = []
      return
    }
  },
  mounted() {
    this.sync()
    // Every 10 seconds
    this.syncInterval = setInterval(this.sync, 10000)
  },
  beforeDestroy() {
    if (this.syncInterval) {
      clearInterval(this.syncInterval)
      this.syncInterval = null
    }
  },
  components: {
    ScheduleOverridesDialog,
    Confirm,
    Stations,
    AccountSelector
  }
}
</script>
