export const PDT = 'America/Los_Angeles'
export const MDT = 'America/Denver'
export const CST = 'America/Chicago'
export const EDT = 'America/New_York'
export const UTC = 'UTC'
export const IST_Chrome = 'Asia/Calcutta'
export const IST_Firefox = 'Asia/Kolkata'


export default function convertDateTimeToISO(inputdt, currentTimeZone) {
    let isoDateTime
    let year = inputdt.getFullYear()
    let month = inputdt.getMonth() + 1 < 10 ? `0${inputdt.getMonth()+1}` : inputdt.getMonth() + 1
    let date = inputdt.getDate() < 10 ? `0${inputdt.getDate()}` : inputdt.getDate()
    let hours = inputdt.getHours() == 0 ? '00' : inputdt.getHours() < 10 ? `0${inputdt.getHours()}` : inputdt.getHours()
    let minutes = inputdt.getMinutes() == 0 ? '00' : inputdt.getMinutes() < 10 ? `0${inputdt.getMinutes()}` : inputdt.getMinutes()
    let seconds = inputdt.getSeconds() == 0 ? '00' : inputdt.getSeconds() < 10 ? `0${inputdt.getSeconds()}` : inputdt.getSeconds()

    // new Date() should have only the acceptable formats of inputs, i;e., dates cannot have hyphens in between them, bcz browsers like firefox cannot process them
    switch (currentTimeZone) {
        case PDT:
            isoDateTime = new Date(`${year} ${month} ${date} ${hours}:${minutes}:${seconds} GMT-07:00`).toISOString().replace('.000', '')
            break;
        case MDT:
            isoDateTime = new Date(`${year} ${month} ${date} ${hours}:${minutes}:${seconds} GMT-06:00`).toISOString().replace('.000', '')
            break;
        case CST:
            isoDateTime = new Date(`${year} ${month} ${date} ${hours}:${minutes}:${seconds} GMT-05:00`).toISOString().replace('.000', '')
            break;
        case EDT:
            isoDateTime = new Date(`${year} ${month} ${date} ${hours}:${minutes}:${seconds} GMT-04:00`).toISOString().replace('.000', '')
            break;
        case UTC:
            isoDateTime = new Date(`${year} ${month} ${date} ${hours}:${minutes}:${seconds} GMT`).toISOString().replace('.000', '')
            break;
        case IST_Chrome:
        case IST_Firefox:
            isoDateTime = new Date(`${year} ${month} ${date} ${hours}:${minutes}:${seconds} GMT+05:30`).toISOString().replace('.000', '')
            break;
    }
    return isoDateTime
}