<template>
  <div>
    <v-row align="end" justify="start" v-if="!getCanEdit">
      <v-col>
        <p class="body-1 text--secondary">This schedule override is read-only for user.</p>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <stations
          multiple
          :station="this.object.stations"
          :view="view"
          :currentStation="currentStation"
           @station-change="stationChange"
        ></stations>
      </v-col>
    </v-row>
    <v-row >
      <v-col cols="12" sm="9" class="pt-5">
        <v-text-field
          v-model="mpid"
          :value="obj.mediapointid"
          @keydown="update"
          :rules="mediaPointRules"
          label="MediaPoint ID"
          :disabled="view == scheduleview || editedIndex != -1"
          require
          ref="mediapointid"
        ></v-text-field>
      </v-col>
      <v-col cols="12" sm="1" class="pt-11">
        <v-icon v-if="!copied" @click="copyToClipboard">mdi-content-copy</v-icon>
        <v-icon v-else>mdi-clipboard-check-multiple</v-icon>
      </v-col>
      <v-col cols="12" sm="2" class="pt-0 pl-0">
        <v-radio-group v-model="startOrEndVal" @change="changeRadio($event)" column mandatory>
          <v-radio
            label="Start"
            value="start"
            :disabled="view == scheduleoverridesview && editedIndex  != -1"
          ></v-radio>
          <v-radio
            label="End"
            value="end"
            :disabled="view == scheduleoverridesview && editedIndex  != -1"
          ></v-radio>
        </v-radio-group>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" sm="6">
        <v-datetime-picker ref="dateTimePickerMatchTime"
          label="Match Time"
          v-model="dateTimeMatchTime"
          :text-field-props="textFieldProps"
          :time-picker-props="timeProps"
          time-format="HH:mm:ss"
          @input="update"
        ></v-datetime-picker>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" sm="6">
        <v-datetime-picker ref="dateTimePickerStart"
          label="Start"
          v-model="dateTimeStart"
          :text-field-props="textFieldProps"
          :time-picker-props="timeProps"
          time-format="HH:mm:ss"
          @input="update"
        ></v-datetime-picker>
      </v-col>
      <v-col cols="12" sm="6">
        <v-datetime-picker ref="dateTimePickerEnd"
          label="End"
          v-model="dateTimeEnd"
          :text-field-props="textFieldProps"
          :time-picker-props="timeProps"
          time-format="HH:mm:ss"
          @input="update"
        ></v-datetime-picker>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" sm="6">
        <v-datetime-picker ref="dateTimePickerEffective"
          label="Effectiveness"
          v-model="dateTimeEffectiveness"
          :text-field-props="textFieldProps"
          :time-picker-props="timeProps"
          time-format="HH:mm:ss"
          @input="update"
        ></v-datetime-picker>
      </v-col>
      <v-col cols="12" sm="6">
        <v-datetime-picker ref="dateTimePickerExpires"
          label="Expires"
          v-model="dateTimeExpires"
          :text-field-props="textFieldProps"
          :time-picker-props="timeProps"
          time-format="HH:mm:ss"
          @input="update"
        ></v-datetime-picker>
      </v-col>
    </v-row>
    <span class="pl-1 pb-0 mb-0 mt-0">
      *Atleast one datetime field should be selected
    </span>
  </div>
</template>

<script>
import {mapGetters} from 'vuex'
import Stations from './Stations'
import stationsMixin from '../../mixins/stations'
import convertDateTimeToISO from '../../utils/datetime'
import _ from 'lodash'
import { DateTime } from 'luxon'

const DEFAULT_DATE_TIME = '0001-01-01T00:00:00Z'

export default {
  name: 'ScheduleOverridesDialog',
  mixins: [stationsMixin],
  props: {
    opened: {
      type: Boolean,
      default: () => false
    },
    startorend :{
      type: String,
      default: () => ''
    },
    obj: {
      type: Object,
      default: () => ({})
    },
    mediapointid: {
      type: String,
      default: () => ''
    },
    view: {
      type: String,
      default: () => 'Schedule Overrides'
    },
    editedIndex: {
      type: Number,
      default: () => -1
    }
  },
  data: () => ({
    noStations: 'No stations available',
    mediaPointRules: [(v) => !!v || 'Media Point is required'],
    stationRules: [(v) => (v && v.length > 0) || 'Station is required'],
    object: {
      stations: [],
      id: '',
      mediapointid: '',
      startorend: '',
      effectiveness: '',
      expires: '',
      matchtime: '',
      start: '',
      end: ''
    },
    textFieldProps: {
      appendIcon: 'event'
    },
    timeProps: {
      useSeconds: true,
      ampmInTitle: true
    },
    scheduleoverridesview: 'Schedule Overrides',
    scheduleview: 'Schedule',
    currentStation: '',
    copied: false
  }),
  computed: {
    ...mapGetters('auth', ['getCanEdit', 'getAccountRoles', 'getToken', 'getAccountID']),
    ...mapGetters('time', ['getTimezone']),
    selectedAllStations() {
      return this.object?.stations?.length === this.stations.length
    },
    selectedSomeStations() {
      return this.object?.stations?.length > 0 && !this.selectedAllStations
    },
    icon() {
      if (this.selectedAllStations) return 'mdi-checkbox-marked'
      if (this.selectedSomeStations) return 'mdi-minus-box'
      return 'mdi-checkbox-blank-outline'
    },
    dateTimeMatchTime: {
      // getter
      get() {
        if (this.object.matchtime) {
            return this.dateTimeInString(this.object.matchtime)
        } else {
            return null
        }
      },
      // setter
      set(newValue) {
        if (newValue != null){
            this.object.matchtime = this.convertDateTime(newValue)
        } else {
            this.object.matchtime = ''
        }
      }
    },
    startOrEndVal: {
      get() {
        return this.radioChanged ? this.startorend : this.object.startorend
      },
      set(newValue) {
        this.object.startorend = newValue
      }
    },
    dateTimeStart : {
      // getter
      get() {
        if (this.object.start) {
            return this.dateTimeInString(this.object.start)
        } else {
            return null
        }
      },
      // setter
      set(newValue) {
        if (newValue != null){
            this.object.start = this.convertDateTime(newValue)
        } else {
            this.object.start = ''
        }
      }
    },
    dateTimeEnd : {
      // getter
      get() {
        if (this.object.end) {
            return this.dateTimeInString(this.object.end)
        } else {
            return null
        }
      },
      // setter
      set(newValue) {
        if (newValue != null){
            this.object.end = this.convertDateTime(newValue)
        } else {
            this.object.end = ''
        }
      }
    },
    dateTimeEffectiveness : {
      // getter
      get() {
        if (this.object.effectiveness) {
            return this.dateTimeInString(this.object.effectiveness)
        } else {
            return null
        }
      },
      // setter
      set(newValue) {
        if (newValue != null){
            this.object.effectiveness = this.convertDateTime(newValue)
        } else {
            this.object.effectiveness = ''
        }
      }
    },
    dateTimeExpires : {
      // getter
      get() {
        if (this.object.expires) {
            return this.dateTimeInString(this.object.expires)
        } else {
            return null
        }
      },
      // setter
      set(newValue) {
        if (newValue != null){
            this.object.expires = this.convertDateTime(newValue)
        } else {
            this.object.expires = ''
        }
      }
    },
    mpid : {
      get() {
        return this.mediapointid ? this.mediapointid : this.object.mediapointid
      },
      set(newValue) {
        this.object.mediapointid = newValue
      }
    }
  },
  methods: {
    ...mapGetters('station', ['getScheduleStation']),
    toggle() {
      this.$nextTick(() => {
        if (this.selectedAllStations) {
          this.object.stations = []
        } else {
          this.object.stations = this.stations.slice()
        }
      })
    },
    update() {
      this.$emit('scheduleOverridesChange', this.object)
    }, 
    dateTimeInString(dateTime){
      if (dateTime && dateTime != DEFAULT_DATE_TIME){
        let format = {
          year: 'numeric',
          month: '2-digit',
          day: '2-digit',
          hour: '2-digit',
          minute: '2-digit',
          second: '2-digit',
          hourCycle: 'h23' // Without this, locale returns 24 for 12AM in 24 hours format
        };
        var dateParts = DateTime.fromISO(dateTime, { zone: this.getTimezone }).toLocaleParts(
          format
        );
        var month, year, day, hour, seconds, mins;
        dateParts.forEach((each) => {
          if (each.type === 'month') month = each.value;
          if (each.type === 'year') year = each.value;
          if (each.type === 'day') day = each.value;
          if (each.type === 'hour') hour = each.value;
          if (each.type === 'minute') mins = each.value;
          if (each.type === 'second') seconds = each.value;
        });
        // DatetimePicker expects the input format in 'yyyy-MM-dd HH:mm:ss'
        let dateString = `${year}-${month}-${day} ${hour}:${mins}:${seconds}`;

        return dateString
      } else {
        return null
      }
    },
    convertDateTime(dateTime) {
      return convertDateTimeToISO(dateTime, this.getTimezone)
    },
    clearFormValues(){
      // Neither the scheduleoverride modal nor the form fields are unmounted on modal close.
      // Hence, clearing field values manually here

      this.object = {
        stations: [],
        id: '',
        mediapointid: '',
        startorend: '',
        effectiveness: '',
        expires: '',
        matchtime: '',
        start: '',
        end: ''
      }
      // Date and time field values in the datetime picker needs to be cleaned up explicitly
      this.$refs.dateTimePickerMatchTime.clearDateTimeFieldValues()
      this.$refs.dateTimePickerStart.clearDateTimeFieldValues()
      this.$refs.dateTimePickerEnd.clearDateTimeFieldValues()
      this.$refs.dateTimePickerEffective.clearDateTimeFieldValues()
      this.$refs.dateTimePickerExpires.clearDateTimeFieldValues()
      
      //changing the radioChanged to false when modal is closed
      this.radioChanged = false
    },
    changeRadio(event){
      this.radioChanged = true
      if (this.opened && event){
        this.$emit('changeRadio', event)
      }
    },
    stationChange (event) {
      this.object.stations = event
      this.update()
    },
    copyToClipboard(){
      this.copied = true
      navigator.clipboard.writeText(this.$refs.mediapointid.value)
      setTimeout(() => this.copied = false, 500)
    }
  },
  emits: ['clearFormValues'],
  watch: {
    obj(val) {
      this.object = val
      this.currentStation = this.view == this.scheduleoverridesview ? val.station : this.getScheduleStation()
    }
  },
  mounted() {
    this.object = _.cloneDeep(this.obj)
  },
  destroyed() {
    this.object = null
  },
  components: {
    Stations,
  }
}
</script>