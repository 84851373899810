import {SET_EMAILS, CLEAR} from '../../types'

export default {
  [SET_EMAILS](state, data) {
    state.emails = data
  },

  [CLEAR](state) {
    state.emails = []
  }
}
