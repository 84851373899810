import axios from 'axios'
import _ from 'lodash'
import {API_BASE} from '../../types'

const requestNotifications = ({commit, state}, obj) => {
  const tasks = new Promise((resolve, reject) => {
    const {station, account, token, from, to} = obj

    let stations = station
    if (stations.length > 0) {
      stations = station.join(',')
    }

    axios
      .get(API_BASE + state.apiRoute, {
        params: {
          token: token,
          station: stations,
          account: account,
          from: from,
          to: to
        },
        validateStatus: (status) => status < 400
      })
      .then((response) => {
        let n = []
        let notifications = response.data

        // Need to get all the object for a station.
        // The response is a object with a key of the station name.
        // {
        //   "EXAMPLE_STATION": [{},{}]
        // }
        _.map(notifications, function (value) {
          n.push(value)
          return
        })

        // Flatten multidimensional array to a single level.
        // It should not be more than 2 levels.
        n = _.flattenDeep(n)

        // Joining the array of notes for formatting in the data table.
        _.forEach(n, function (value, key) {
          value.notes = _.join(value.notes, ' ')
          n[key] = value
        })

        if (n.length === 0) {
          commit('SET_NOTIFICATIONS', [])
          resolve([])
          return []
        }

        commit('SET_NOTIFICATIONS', n)
        resolve(n)
      })
      .catch((err) => reject(err))
    return null
  })
  return tasks
}

export default {
  requestNotifications
}
