<template>
  <div>
    <v-img
      @click.native="route('home')"
      :src="logo"
      max-height="150"
      max-width="150"
      contain
      v-if="logo"
    ></v-img>
    <v-img
      @click.native="route('home')"
      :max-width="80"
      max-height="80"
      :src="require('@/assets/img/lrm_white_logo.svg')"
      v-else
    >
    </v-img>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  name: 'Logo',
  props: {
    header: {
      type: Boolean,
      default: () => false
    },
    auth: {
      type: Boolean,
      default: () => false
    }
  },
  asyncComputed: {
    logo: {
      get() {
        return axios.get('/api/v1/logos').then((resp) => {
          return resp.data.url
        })
      },
      default: ''
    }
  },
  methods: {
    route(routeName) {
      this.$router
        .push({
          name: routeName
        })
        .catch(() => {})
    }
  }
}
</script>
