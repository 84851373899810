import actions from './actions'
import mutations from './mutations'
import getters from './getters'

const state = {
    username: '',
    userID: '',
    accounts: [],
    token: '',
    role: '',
    roleTitle: '',
    readOnly: true,
    authenticated: false,
    account: {},
    accountName: '',
    accountID: '',
    accountRoles: {},
    mpxRoleURL: 'https://access.auth.theplatform.com/data/Role?schema=1.0&form=cjson',
    mpxPermissionURL: 'https://access.auth.theplatform.com/data/Permission?schema=1.0&form=cjson',
    authURL: 'https://identity.auth.theplatform.com/idm/web/Authentication/signIn?schema=1.0&form=json',
    accountURL: 'https://access.auth.theplatform.com/data/Account'
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}