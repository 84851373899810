import {SET_RULES, CLEAR} from '../../types'

export default {
  [SET_RULES](state, data) {
    state.rules = data
  },
  [CLEAR](state) {
    state.rules = []
  }
}
