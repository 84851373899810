// import _ from 'lodash';
import * as types from '../../types'

export default {
  [types.SET_USERNAME](state, data) {
    state.username = data
  },
  [types.SET_USERID](state, data) {
    state.userID = data
  },
  [types.SET_ACCOUNT](state, data) {
    state.account = data
  },
  [types.SET_ACCOUNTNAME](state, data) {
    state.accountName = data
  },
  [types.SET_ACCOUNTID](state, data) {
    state.accountID = data
  },
  [types.SET_ACCOUNTS](state, data) {
    state.accounts = data
  },
  [types.SET_ACCOUNT_ROLES](state, data) {
    state.accountRoles = data
  },
  [types.SET_TOKEN](state, data) {
    state.token = data
  },
  [types.SET_ROLE](state, data) {
    state.role = data
  },
  [types.SET_ROLE_TITLE](state, data) {
    state.roleTitle = data
  },
  [types.SET_READ_ONLY](state, data) {
    state.readOnly = data
  },
  [types.SET_ISAUTHENTICATED](state, data) {
    state.authenticated = data
  },
  [types.SET_SIGNOUT](state) {
    state.authenticated = false
    state.username = ''
    state.userID = ''
    state.accounts = []
    state.token = ''
    state.role = ''
    state.roleTitle = ''
    state.readOnly = true
    state.account = {}
    state.accounts = []
    state.accountID = ''
    state.accountName = ''
  }
}
