<template>
<div>
    <v-menu offset-y open-on-hover>
        <template #activator="{on}">
            <div v-on="on" class="subtitle-1" style="cursor: default">
                {{ timeDisplay }}
                <v-icon style="padding-bottom: 3px" color="white" title="Change timezone">arrow_drop_down</v-icon>
            </div>
        </template>
        <v-list class="pt-0 pb-0">
            <v-list-item :key="t.zone" @click.native="setTimezone(t.zone)" v-for="t in times">
                <v-list-item-action>
                    <v-icon v-if="t.zone === getTimezone">check</v-icon>
                </v-list-item-action>
                <v-list-item-content>
                    <v-list-item-title class="mr-3 subtitle-1">{{
              t.time
            }}</v-list-item-title>
                </v-list-item-content>
            </v-list-item>
        </v-list>
    </v-menu>
</div>
</template>

<script>
import axios from 'axios'
import _ from 'lodash'
import {
    DateTime
} from 'luxon'
import {
    mapActions,
    mapGetters
} from 'vuex'
import {
    PDT,
    MDT,
    CST,
    EDT,
    UTC,
    IST_Chrome
} from '../../utils/datetime'

export default {
    name: 'ServerTime',
    data: () => {
        return {
            format: {
                month: 'short',
                day: '2-digit',
                year: 'numeric',
                hour: '2-digit',
                minute: '2-digit',
                second: '2-digit',
                timeZoneName: 'short',
                hour12: false
            },
            serverTime: DateTime.local(),
            tickInterval: null,
            syncInterval: null,
            timezones: [PDT, MDT, CST, EDT, UTC, IST_Chrome]
        }
    },
    computed: {
        ...mapGetters('time', ['getTimezone']),
        timeDisplay() {
            return this.serverTime
                .setZone(this.getTimezone)
                .toLocaleString(this.format)
        },
        times() {
            const offsetNames = []
            const timesToReturn = []
            _.forEach(this.timezones, (tz) => {
                const calctime = DateTime.fromISO(this.serverTime.toISO())
                    .setZone(tz)
                const timeStr = calctime.toLocaleString(this.format)
                const currentTimeZoneOffsetNameShort = calctime.offsetNameShort
                // IST can either be Asia/Calcutta or Asia/Kolkata
                // Firefox adds 2 IST formats in the times dropdown automatically based on the system settings
                // Hence the check
                if (!offsetNames.includes(currentTimeZoneOffsetNameShort)) {
                    offsetNames.push(currentTimeZoneOffsetNameShort)
                    timesToReturn.push({
                        zone: tz,
                        time: timeStr
                    })
                }
            })

            return timesToReturn
        }
    },
    methods: {
        ...mapActions('time', ['setTimezone']),
        tick() {
            this.serverTime = this.serverTime.plus({
                second: 1
            })
        },
        sync() {
            axios.get('/proxy/v1/status').then((resp) => {
                this.serverTime = DateTime.fromISO(resp.data.serverTime)
            })
        }
    },
    mounted() {
        this.sync()
        this.tickInterval = setInterval(this.tick, 1000)
        this.syncInterval = setInterval(this.sync, 60000)
        // if the user is an unsupported timezone, add it
        if (_.indexOf(this.timezones, this.getTimezone) < 0) {
            this.timezones.push(this.getTimezone)
        }
    },
    beforeDestroy() {
        if (this.tickInterval) {
            clearInterval(this.tickInterval)
            this.tickInterval = null
        }
        if (this.syncInterval) {
            clearInterval(this.syncInterval)
            this.syncInterval = null
        }
    }
}
</script>
