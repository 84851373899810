import axios from 'axios'
import {API_BASE} from '../../types'

const updateRule = ({state, commit}, obj) => {
  const ruleData = new Promise((resolve, reject) => {
    const {station, account, token, data} = obj

    let stations = station
    if (stations.length > 0) {
      stations = station.join(',')
    }

    const d = {
      rules: data
    }

    axios
      .put(API_BASE + state.apiRoute, d, {
        params: {
          token,
          station: stations,
          account
        },
        validateStatus: (status) => status < 400
      })
      .then((response) => {
        let rules = response.data
        if (rules === null || rules.length === 0) {
          commit('SET_RULES', [])
          resolve([])
        }

        commit('SET_RULES', rules)
        resolve(rules)
      })
      .catch((error) => {
        reject(error)
      })

    return null
  })

  return ruleData
}

const createRule = ({state, commit}, obj) => {
  const ruleData = new Promise((resolve, reject) => {
    const {account, token, data} = obj

    const d = {
      rules: data
    }

    axios
      .post(API_BASE + state.apiRoute, d, {
        params: {
          token,
          account
        },
        validateStatus: (status) => status < 400
      })
      .then((response) => {
        let rules = response.data

        if (rules === null || rules.length === 0) {
          commit('SET_RULES', [])
          resolve([])
        }

        commit('SET_RULES', rules)
        resolve(rules)
      })
      .catch((error) => {
        reject(error)
      })
    return null
  })

  return ruleData
}

const deleteRules = ({state}, obj) => {
  const ruleData = new Promise((resolve, reject) => {
    const {account, token, data} = obj

    const d = {
      rules: data
    }

    axios
      .delete(API_BASE + state.apiRoute, {
        params: {
          token,
          account
        },
        data: d,
        validateStatus: (status) => status < 400
      })
      .then(() => {
        resolve()
      })
      .catch((error) => {
        reject(error)
      })

    return null
  })

  return ruleData
}

const requestRules = ({commit, state}, obj) => {
  const rules = new Promise((resolve, reject) => {
    const {station, account, token} = obj

    let stations = station
    if (stations.length > 0) {
      stations = station.join(',')
    }

    axios
      .get(API_BASE + state.apiRoute, {
        params: {
          token,
          account,
          station: stations
        },
        validateStatus: (status) => status < 400
      })
      .then((response) => {
        let rules = response.data

        if (rules === null || rules.length === 0) {
          commit('SET_RULES', [])
          resolve([])
          return []
        }

        if (rules.rules === null) {
          commit('SET_RULES', [])
          resolve([])
          return []
        }
        commit('SET_RULES', rules.rules)
        resolve(rules.rules)
      })
      .catch((err) => reject(err))
    return null
  })
  return rules
}

export default {
  requestRules,
  createRule,
  updateRule,
  deleteRules
}
