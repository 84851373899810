import _ from 'lodash'

const getUsername = (state) => state.username
const getAvatar = (state) => _.upperCase(state.username[0])
const getUserID = (state) => state.userID
const getToken = (state) => state.token
const getAccountIDs = (state) => _.map(_.orderBy(state.accounts, 'id'), 'id')
const getAccountNames = (state) =>
  _.map(_.orderBy(state.accounts, 'name'), 'name')
const getAccountName = (state) => state.accountName
const getAccountID = (state) => state.accountID
const getAccounts = (state) => state.accounts
const getAccount = (state) => state.account
const getAccountRoles = (state) => state.accountRoles

// If there are any true account roles except auth kill read only it will return true.
// This exists for the case when there is an auth kill read only role applied but no others. 
// This will disable the save and fill defaults buttons.
const hasWritableAccountRoles = (state) => _.keys(_.pickBy(state.accountRoles, (v,k) => {
  if (k == 'authKillReadOnly') return false
  return v
})).length > 0

// hasAccountRolesForEvent is needed to enable or disable forms or form fields.
// This function builds an array of enabled account roles. 
// If the length of the array is more than 0 the function will return true.
const hasAccountRolesForEvent = (state) => (e) => {
  let keys = _.keys(_.pickBy(state.accountRoles, (v, k) => {
    switch (k) {
      case 'uhd':
        if (!e.uhdavailable) return false
        break;
      default:
        break;
    }
    return v
  }))

  if (!keys.includes('network') && e.type == 'network') {
    return false
  }

  return keys.length > 0
}

const getRole = (state) => state.role
const getRoleName = (state) => state.roleTitle
const getCanEdit = (state) => !state.readOnly
const isAuthenticated = (state) => state.authenticated

export default {
  getUsername,
  getAvatar,
  getUserID,
  getAccountName,
  getAccountID,
  getAccounts,
  getAccount,
  getAccountNames,
  getAccountIDs,
  getAccountRoles,
  hasAccountRolesForEvent,
  hasWritableAccountRoles,
  getToken,
  getRole,
  getRoleName,
  getCanEdit,
  isAuthenticated
}
