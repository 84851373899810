import {SET_TASKS, SET_TASKS_FROM, SET_TASKS_TO, CLEAR} from '../../types'
import {DateTime} from 'luxon'

export default {
  [SET_TASKS](state, data) {
    state.tasks = data
  },
  [SET_TASKS_FROM](state, data) {
    state.from = data
  },
  [SET_TASKS_TO](state, data) {
    state.to = data
  },
  [CLEAR](state) {
    state.tasks = []
    state.from = DateTime.local().toISODate()
    state.to = DateTime.local().toISODate()
  }
}
