import axios from 'axios'
import {API_BASE} from '../../types'

const requestTasks = ({commit, state}, obj) => {
  const tasks = new Promise((resolve, reject) => {
    const {station, account, token, from, to} = obj

    let stations = station
    if (stations.length > 0) {
      stations = station.join(',')
    }

    axios
      .get(API_BASE + state.apiRoute, {
        params: {
          token: token,
          station: stations,
          account: account,
          from: from,
          to: to
        },
        validateStatus: (status) => status < 400
      })
      .then((response) => {
        let events = response.data

        if (events === null || events.length === 0) {
          commit('SET_TASKS', [])
          resolve([])
          return []
        }

        events.events.forEach((task) => {
          task['showfull'] = task.showtitle
          if (task.season !== '' || task.episode !== '') {
            task[
              'showfull'
            ] = `${task.showtitle} - S[${task.season}] E[${task.episode}]`
          }
        })

        commit('SET_TASKS', events.events)
        resolve(events.events)
      })
      .catch((err) => reject(err))
    return null
  })
  return tasks
}


const setTasksDateFrom = ({commit}, date) => {
  commit('SET_TASKS_FROM', date)
}

const setTasksDateTo = ({commit}, date) => {
  commit('SET_TASKS_TO', date)
}

export default {
  requestTasks,
  setTasksDateFrom,
  setTasksDateTo
}
