import Vue from 'vue'
import Vuetify from 'vuetify/lib'
import '@mdi/font/css/materialdesignicons.css'
import colors from 'vuetify/lib/util/colors'

Vue.use(Vuetify)

export default new Vuetify({
  theme: {
    dark: false,
    themes: {
      light: {
        primary: '#8d6e63',
        primaryText: '#ffffff',
        secondary: '#D7CCC8',
        secondaryText: '#000000',
        accent: colors.grey.darken2,
        error: colors.red.lighten1,
        success: colors.green.lighten1
      }
    },
    options: {
      minifyTheme: function (css) {
        return process.env.NODE_ENV === 'production'
          ? css.replace(/[\r\n|\r|\n]/g, '')
          : css
      }
    }
  }
})
