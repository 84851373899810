import {mapGetters, mapActions} from 'vuex'

export default {
  computed: {
    ...mapGetters('station', ['getStation', 'getStationNames']),
    ...mapGetters('auth', ['getAccountID', 'getToken'])
  },
  methods: {
    ...mapActions('station', ['requestStations']),
  },
  asyncComputed: {
    stations: {
      lazy: true,
      get() {
        return this.requestStations({
          account: this.getAccountID,
          token: this.getToken
        })
          .then((s) => s)
          .catch(() => {
            return []
          })
      },
      default: []
    }
  }
}
