<template>
  <v-app>
    <app-header></app-header>
    <v-main>
      <AppSnackbar></AppSnackbar>
      <v-container fluid>
        <router-view></router-view>
      </v-container>
    </v-main>
    <app-footer></app-footer>
  </v-app>
</template>

<script>
import AppHeader from './components/app/AppHeader'
import AppFooter from './components/app/AppFooter'
import AppSnackbar from './components/app/AppSnack'

export default {
  name: 'app',
  components: {
    AppHeader,
    AppFooter,
    AppSnackbar
  }
}
</script>

<style>
@import '~vuetify/dist/vuetify.min.css';
</style>
