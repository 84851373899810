var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',[(_vm.isUIReady)?_c('v-row',{staticClass:"secondary pt-1"},[_c('v-col',{staticClass:"pb-0"},[_c('account-selector',{attrs:{"dense":""},on:{"change":_vm.setPermissions}})],1),_c('v-col',{staticClass:"pb-0"},[_c('stations',{attrs:{"dense":"","multiple":"","station":_vm.getNotificationsStation},on:{"station-change":function($event){_vm.loading = true
          _vm.setNotificationsStation($event)}}})],1)],1):_vm._e(),_c('v-card',{staticClass:"mt-3",attrs:{"flat":""}},[_c('v-card-title',{staticClass:"pr-0 pl-0 pb-0"},[_c('v-row',{attrs:{"flat":"","dense":""}},[_c('v-col',{staticClass:"d-flex align-center"},[_c('h1',{staticClass:"display-1 primary--text font-weight-bold"},[_vm._v(" Notifications ")])]),_c('v-spacer'),_c('v-col',{staticClass:"pl-4",attrs:{"cols":"12","lg":"4"}},[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":"","clearable":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-col',{attrs:{"cols":"12","lg":"2","dense":""}},[_c('v-menu',{ref:"notificationdatepicker",attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
return [_c('v-text-field',_vm._g({staticClass:"pl-3",attrs:{"label":"Date","hint":"MM/DD/YYYY format","append-icon":"mdi-calendar","persistent-hint":"","readonly":""},model:{value:(_vm.datePickerFormatted),callback:function ($$v) {_vm.datePickerFormatted=$$v},expression:"datePickerFormatted"}},on))]}}]),model:{value:(_vm.datePickerOpen),callback:function ($$v) {_vm.datePickerOpen=$$v},expression:"datePickerOpen"}},[_c('v-date-picker',{attrs:{"no-title":""},on:{"change":_vm.datapickerChange,"input":function($event){_vm.datePickerOpen = false}},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}},[_c('v-spacer'),_c('v-btn',{staticClass:"primaryText--text",attrs:{"color":"primary"},on:{"click":_vm.today}},[_vm._v("Today")])],1)],1)],1)],1)],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"item-key":"guid","items":_vm.notifications,"search":_vm.search,"no-results-text":"No matching notifications found.","no-data-text":"No notifications found. Maybe a station needs to be selected","loading-text":"Loading notifications...","loading":_vm.loading},scopedSlots:_vm._u([{key:"item.station",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("fillEmpty")(item.station.toUpperCase()))+" ")]}},{key:"item.notes",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("fillEmpty")(item.notes))+" ")]}},{key:"item.startTime",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("userTime")(item.startTime))+" ")]}},{key:"item.endTime",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("userTime")(item.endTime))+" ")]}}],null,true)})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }