<template>
  <v-dialog v-model="dialog" :max-width="options.width" @keydown.esc="cancel">
    <v-card>
      <v-toolbar :color="options.color" :class="options.class" flat>
        <v-toolbar-title class="white--text">{{ title }}</v-toolbar-title>
      </v-toolbar>
      <v-subheader v-show="!!detailsHeader">{{ detailsHeader }}</v-subheader>
      <v-card-text v-show="!!details">
        <p class="mb-0 pl-1" v-for="(detail, index) in details" :key="index">
          {{ detail }}
        </p>
      </v-card-text>
      <v-card-actions class="pt-0">
        <v-spacer></v-spacer>
        <v-btn color="grey" text @click.native="cancel">cancel</v-btn>
        <v-btn :color="options.agreeBtnColor" @click.native="agree">{{
          options.agreeBtnText
        }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
/**
 * Vuetify Confirm Dialog component

 * Insert component where you want to use it:
   <confirm ref="confirm"></confirm>

 * Call it:
   this.$refs.confirm.open('Delete', ["detail"], 'You will delete the following:', { color: 'red' }).then((confirm) => {})

 * Or use await:
   if ( await this.$refs.confirm.open( 'Delete?', ["detail"], 'You will delete the following:') ) {
    // yes
   } else {
    // cancel
   }

**/

export default {
  data: () => ({
    dialog: false,
    resolve: null,
    reject: null,
    details: null,
    detailsHeader: null,
    title: null,
    options: {
      color: 'primary',
      class: 'primaryText--text',
      width: 400,
      agreeBtnText: 'delete',
      agreeBtnColor: 'error'
    }
  }),
  methods: {
    open(title, details, detailsHeader, options) {
      this.dialog = true
      this.title = title
      this.details = details
      this.detailsHeader = detailsHeader
      this.options = Object.assign(this.options, options)
      return new Promise((resolve, reject) => {
        this.resolve = resolve
        this.reject = reject
      })
    },
    agree() {
      this.resolve(true)
      this.dialog = false
    },
    cancel() {
      this.resolve(false)
      this.dialog = false
    }
  }
}
</script>
