<template>
  <v-form
    :disabled="
      !getCanEdit ||
      !hasAccountRolesForEvent(object) ||
      !hasWritableAccountRoles
    "
    v-model="valid"
    ref="form"
  >
    <v-container>
      <v-card-text>
          <schedule-overrides-dialog ref="scheduleOverridesDialog"
            :obj="editedScheduleOverride"
            :mediapointid="mediapointid"
            @scheduleOverridesChange="changeScheduleOverride"
            @changeRadio="changeRadio"
            v-show="getAccountRoles.scheduleOverride && view == scheduleview"
            :view="scheduleview"
            :opened="opened"
            :startorend="startorend"
          ></schedule-overrides-dialog>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          class="body-1 text--secondary"
          text
          v-if="
            !getCanEdit ||
            !hasAccountRolesForEvent(object) ||
            !hasWritableAccountRoles
          "
          @click.native="cancel"
          >Close</v-btn
        >
        <v-btn
          class="body-1 text--secondary"
          text
          v-if="
            getCanEdit &&
            hasAccountRolesForEvent(object) &&
            hasWritableAccountRoles
          "
          @click.native="cancel"
          >Cancel</v-btn
        >
        <v-btn
          depressed
          color="accent"
          v-if="
            getCanEdit &&
            hasAccountRolesForEvent(object) &&
            hasWritableAccountRoles
          "
          :disabled="!valid || !getCanEdit || !hasWritableAccountRoles"
          @click.native="save(object)"
          >Save</v-btn
        >
      </v-card-actions>
    </v-container>
  </v-form>
</template>

<script>

import {mapGetters, mapActions} from 'vuex'
import ScheduleOverridesDialog from './ScheduleOverrides'
import {SnackBus} from '../../main'
import _ from 'lodash'

const DEFAULT_DATE_TIME = '0001-01-01T00:00:00Z'

export default {
  name: 'ScheduleOverridesEvents',
  props: {
    view: {
      type: String,
      default: () => ''
    }
  },
  data: () => ({
    startorend: '',
    opened: false,
    object: {},
    mediapointid: '',
    resolve: null,
    reject: null,
    valid: false,
    default: {},
    empty: {
      station: '',
      application: '',
      showtitle: '',
      series: false,
      season: '',
      episode: '',
      embargo: false,
      startover: false,
      lookback: false,
      authkill: false,
      takeuhd: false,
      uhdavailable: false,
      type: ''
    },
    emptyScheduleOverride: {
      stations: [],
      id: '',
      mediapointid: '',
      startorend: '',
      effectiveness: '',
      expires: '',
      matchtime: '',
      start: '',
      end: ''
    },
    editedScheduleOverride: {
      stations: [],
      id: '',
      mediapointid: '',
      startorend: '',
      effectiveness: '',
      expires: '',
      matchtime: '',
      start: '',
      end: ''
    },
    // the properties in this object should match that of in edited schedule override object
    scheduleOverrideOnOpen: {
      stations: [],
      id: '',
      mediapointid: '',
      startorend: '',
      effectiveness: '',
      expires: '',
      matchtime: '',
      start: '',
      end: ''
    },
    scheduleview: 'Schedule'
  }),
  computed: {
    ...mapGetters('auth', [
      'getCanEdit',
      'getAccountID',
      'getToken',
      'getAccountRoles',
      'hasAccountRolesForEvent',
      'hasWritableAccountRoles'
    ])
  },
  methods: {
    ...mapActions('event', ['requestEventOrSO']),
    ...mapActions('scheduleoverride', [
      'updateScheduleOverride'
    ]),
    ...mapGetters('station', ['getScheduleStation']),
    isFormChanged: function(){
      if (this.scheduleOverrideOnOpen.matchtime !== this.editedScheduleOverride.matchtime) {
        return true
      }
      if (this.scheduleOverrideOnOpen.effectiveness !== this.editedScheduleOverride.effectiveness) {
        return true
      }
      if (this.scheduleOverrideOnOpen.expires !== this.editedScheduleOverride.expires) {
        return true
      }
      if (this.scheduleOverrideOnOpen.start !== this.editedScheduleOverride.start) {
        return true
      }
      if (this.scheduleOverrideOnOpen.end !== this.editedScheduleOverride.end) {
        return true
      }
      if (this.scheduleOverrideOnOpen.startorend !== this.editedScheduleOverride.startorend && this.editedScheduleOverride.startorend) {
        return true
      }
      if (!_.isEqual(this.scheduleOverrideOnOpen.stations, this.editedScheduleOverride.stations)){
        return true
      }
      return false
    },
    open(event, identifier) {

      this.$refs.form.resetValidation()
      this.opened = true
      this.object = event
      this.mediapointid = identifier;
      this.default = Object.assign(this.empty, event)
      let eventOrschedOverride = {}
      this.requestEventOrSO({
            station: this.getScheduleStation(),
            account: this.getAccountID,
            token: this.getToken,
            startorend: 'start',
            mpid: event.guid
          }).then(eventorso => {
              if (eventorso == null) return {}

              if (eventorso?.startorend) {
                eventOrschedOverride.startorend = eventorso.startorend
                this.startorend = eventorso.startorend
                this.$emit('changeSOHeaderColor', eventorso.startorend)
              }
              if (eventorso?.start) {
                eventOrschedOverride.start = eventorso.start
              }
              if (eventorso?.expires) {
                eventOrschedOverride.expires = eventorso.expires
              }
              if (eventorso?.effectiveness) {
                eventOrschedOverride.effectiveness = eventorso.effectiveness
              }
              if (eventorso?.end) {
                eventOrschedOverride.end = eventorso.end
              }
              eventOrschedOverride.matchtime = eventorso?.matchtime
              if (eventOrschedOverride.matchtime?.toString() == DEFAULT_DATE_TIME || !eventOrschedOverride.matchtime) {
                eventOrschedOverride.matchtime = ''
              }
               if (eventorso?.stations) {
                eventOrschedOverride.stations =  eventorso.stations
               }else if (eventorso?.station){
                eventOrschedOverride.stations = [eventorso.station]
              }
              this.editedScheduleOverride = eventOrschedOverride
              this.scheduleOverrideOnOpen = _.cloneDeep(this.editedScheduleOverride)
            })
            .catch(() => {
              SnackBus.$emit('error', 'Error getting event or schedule override')
              return {}
            })

      return new Promise((resolve, reject) => {
        this.resolve = resolve
        this.reject = reject
      })
    },
    save(object) {
      if (!this.isFormChanged()){
        SnackBus.$emit('error', 'No changes to create/update.' )
        return
      }
      if (this.$refs.form.validate() && this.getCanEdit) {
          let scheduleoverride = _.cloneDeep({...this.editedScheduleOverride, mediapointid: this.mediapointid})
          if (scheduleoverride?.stations?.length > 1) {
            scheduleoverride.id = ''
          }
          if (scheduleoverride?.matchtime == '') {
            scheduleoverride.matchtime = DEFAULT_DATE_TIME
          }
          if (scheduleoverride?.start == '') {
            scheduleoverride.start = DEFAULT_DATE_TIME
          }
          if (scheduleoverride?.end == '') {
            scheduleoverride.end = DEFAULT_DATE_TIME
          }
          if (scheduleoverride?.expires == '') {
            scheduleoverride.expires = DEFAULT_DATE_TIME
          }
          if (scheduleoverride?.effectiveness == '') {
            scheduleoverride.effectiveness = DEFAULT_DATE_TIME
          }
          scheduleoverride.station = this.getScheduleStation()

          this.updateScheduleOverride({
            station: scheduleoverride.stations,
            account: this.getAccountID,
            token: this.getToken,
            data: [scheduleoverride]
          })
          .then(() => {
          let isOverridden = object.scheduleoverridestate.startoverridden || object.scheduleoverridestate.endoverridden
              if (scheduleoverride.stations.length < 2){
                SnackBus.$emit('open', {
                  text: `Successfully ${isOverridden ? 'updated' : 'created'} schedule override for the mediapoint ${scheduleoverride.mediapointid} in station ${scheduleoverride.station}`,
                  icon: 'check',
                  color: 'green'
                })
              } else {
                SnackBus.$emit('open', {
                  text: `Successfully ${isOverridden ? 'updated' : 'created'} schedule override for the mediapoint ${scheduleoverride.mediapointid} in station ${scheduleoverride.station}. ${isOverridden ? 'Updating' : 'Creating'} overrides for other stations...`,
                  icon: 'check',
                  color: 'green'
                })
              }
            setTimeout(() => this.$emit('requestEventsOnSave'), 1000)
          })
          .catch((err) => {
            let isOverridden = object.scheduleoverridestate.startoverridden || object.scheduleoverridestate.endoverridden
            SnackBus.$emit('error', `Error ${isOverridden ? 'updating' : 'creating'} schedule override`, err )
          })      
        this.close(this.object)
      }    
    },
    clearFormValues(){      
      this.$refs.scheduleOverridesDialog.clearFormValues()
      this.editedScheduleOverride = Object.assign({}, this.emptyScheduleOverride)
      this.scheduleOverrideOnOpen = Object.assign({}, this.emptyScheduleOverride)
    },
    cancel() {
      this.close({})
    },
    close(obj) {
      this.object = this.empty
      this.default = this.empty
      // On open, fields are empty whenever form is cleared on model close in the Schedule view
      // this.clearFormValues()
      if (this.resolve != null) {
        this.resolve(obj)
      }
    },
    applyDefaults() {
      this.object.application = this.default.application

      if (this.getAccountRoles.embargo) {
        this.object.embargo = this.default.embargo
      }

      if (this.getAccountRoles.startOver) {
        this.object.startover = this.default.startover
      }

      if (this.getAccountRoles.lookBack) {
        this.object.lookback = this.default.lookback
      }

      if (this.getAccountRoles.uhd && this.default.uhdavailable) {
        this.object.uhdavailable = this.default.uhdavailable
        this.object.takeuhd = this.default.takeuhd
      }

      if (this.getAccountRoles.authKill) {
        this.object.authkill = this.default.authkill
      }
    },
    changeRadio(startorend) {
      this.$emit('changeSOHeaderColor', startorend)
      this.startorend = startorend
      let eventOrschedOverride = this.emptyScheduleOverride

      if(this.view == this.scheduleview) {
          this.requestEventOrSO({
            station: this.getScheduleStation(),
            account: this.getAccountID,
            token: this.getToken,
            startorend: startorend,
            mpid: this.object.guid
          }).then(eventorso => {
              if (eventorso == null) return {}
              if (eventorso?.start) {
                eventOrschedOverride.start = eventorso.start
              } else {
                eventOrschedOverride.start = ''
              }
              if (eventorso?.expires) {
                eventOrschedOverride.expires = eventorso.expires
              }
              if (eventorso?.effectiveness) {
                eventOrschedOverride.effectiveness = eventorso.effectiveness
              }
              if (eventorso?.end) {
                eventOrschedOverride.end = eventorso.end
              }
              eventOrschedOverride.matchtime = eventorso?.matchtime
              if (eventOrschedOverride.matchtime?.toString() == DEFAULT_DATE_TIME || !eventOrschedOverride.matchtime) {
                eventOrschedOverride.matchtime = ''
              }
              if (eventorso?.startorend) {
                eventOrschedOverride.startorend = eventorso.startorend
              }
              if (eventorso?.stations) {
                eventOrschedOverride.stations = eventorso.stations
              } else if (eventorso?.station){
                eventOrschedOverride.stations = [eventorso.station]
              }

              this.editedScheduleOverride = eventOrschedOverride    
              this.scheduleOverrideOnOpen =_.cloneDeep(this.editedScheduleOverride)           
            })
            .catch(() => {
              SnackBus.$emit('error', 'Error getting event or schedule override')
              return {}
            })
        }
    },
    changeScheduleOverride(event) {
      this.editedScheduleOverride = event
      setTimeout(() => this.$refs.form.validate(), 20)
    }
  },
  components: {
    ScheduleOverridesDialog
  }
}
</script>
