import axios from 'axios'
import {API_BASE} from '../../types'

const requestEmails = ({commit, state, rootState}, data) => {
  const stationData = new Promise((resolve, reject) => {
    const {accountID, token} = rootState.auth
    const {station} = data

    if (station === '') {
      return
    }

    if (accountID === '') {
      reject('Account is empty')
    }

    if (token === '') {
      reject('Token is empty')
    }

    axios
      .get(API_BASE + state.apiRoute, {
        params: {
          station,
          token,
          account: accountID
        },
        validateStatus: (status) => status < 400
      })
      .then((response) => {
        let data = response.data
        if (data === null || data.length === 0) {
          reject(data)
        }

        if (data.responseCode > 400) {
          if (typeof data.emails !== 'undefined') {
            reject(data)
          } else {
            reject(data)
          }
        }

        if (data.emails === null) {
          commit('SET_EMAILS', [])
          resolve([])
        }

        commit('SET_EMAILS', data.emails)
        resolve(data.emails)
      })
      .catch((err) => reject(err))
  })
  return stationData
}

const updateEmails = ({state, rootState}, obj) => {
  const ruleData = new Promise((resolve, reject) => {
    const {accountID, token} = rootState.auth
    const {station, data} = obj

    const d = {
      emails: data
    }

    axios
      .put(API_BASE + state.apiRoute, d, {
        params: {
          token,
          station,
          account: accountID
        },
        validateStatus: (status) => status < 400
      })
      .then(() => {
        resolve()
      })
      .catch((error) => {
        reject(error)
      })

    return null
  })

  return ruleData
}

export default {
  requestEmails,
  updateEmails
}
