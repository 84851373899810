import actions from './actions'
import mutations from './mutations'
import getters from './getters'

const state = {
  emails: [],
  apiRoute: 'emails'
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
